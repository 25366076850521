.form-error {
  color: $Red-Orange;
  font-size: 14px;
  font-family: $National2Medium;
}

.form-error-validation-img {
  img {
    margin-right: 0.5rem;
  }
}

.material-input.field input {
  background: #f8f7f5;
}

.formelement-error {
  border-bottom: 2px solid $Red-Orange !important;
}

.form-checkbox-container {
  input[type='checkbox']:checked {
    background-color: #4d4d50 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input[type='checkbox']:after {
    content: '✓';
    color: #ffff;
    font-weight: 900;
    font-size: 0.6em;
    display: none;
    border-radius: 0 !important;
  }

  input[type='checkbox']:checked:after {
    display: block;
  }

  input[type='checkbox'] {
    cursor: pointer;
    outline: none;
    border: 1px solid #4d4d50;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px !important;
    width: 16px;
    height: 16px;
    margin-left: 10px;
    background-color: #f8f7f5;
  }
}

.material-input.field label.masktextlabel {
  cursor: text !important;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 2.125rem) scale(1.5) !important;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.1px;
}

.material-input.field input:focus + label.masktextlabel,
.material-input.field input.phnContainsval + label.masktextlabel {
  transform: translate(0, 0) scale(1) !important;
  cursor: pointer !important;
}
