.downloadapp-component-container {
  background-color: #202124;
  margin-top: 40px;
  @media #{$mobile-only} {
    margin-top: 20px;
    height: auto;
  }
  @media #{$breakpoint-below-tablet} {
    height: auto;
  }
  .stri-ICON {
    //padding-left: 17%;
    @media #{$breakpoint-above-destop-wide} {
      //padding-left: 18%;
    }
    @media #{$breakpoint-below-tablet} {
      //padding-left: unset;
      text-align: center;
    }
    img {
      width: 56px;
      height: 34px;
      @media #{$mobile-only} {
        width: 39px;
        height: 20px;
        margin-top: -7px;
      }
    }
    // @media #{$breakpoint-above-destop-wide} {
    // 	padding-left: 530px;
    // }
  }
  .downloadapp-image-container {
    padding: 1.312rem 0 0 0 !important;
    // padding: 1.6% 13.5% 0px 17%;
    @media #{$breakpoint-above-desktop} {
      margin-right: 93px !important;
    }
    @media #{$mobile-only} {
      padding: 25px 0 0 0 !important;
    }
    @media #{$breakpoint-below-tablet} {
      padding: 30px 0 0 0;
    }
    @media #{$breakpoint-above-destop-wide} {
      //padding: 21px 0px 0px 18%;
      padding: 1.312rem 0 0 0;
    }
    // @media #{$breakpoint-above-destop-wide} {
    // 	padding: 21px 500px 0px 530px;
    // }
    .app-detail-section {
      display: flex;
      @media #{$breakpoint-below-tablet} {
        display: unset;
      }
      .store-img {
        display: flex;
        margin-top: 6%;
        @media #{$breakpoint-below-tablet} {
          display: block;
        }
        @media #{$mobile-only} {
          margin-top: 6.4%;
          margin-left: 2.5%;
        }
        a {
          margin: 0 2% 0 0;
          @media #{$mobile-only} {
            margin: 0 10px 0 0;
          }
          img {
            border: 1px solid #fff;
            border-radius: 3px;
            height: 45px;
          }
        }
      }
      .app-store-details {
        @media #{$breakpoint-below-tablet} {
          text-align: center;
        }
      }
      // .ml-set{
      // 	@media #{$breakpoint-below-and-above-medium-desktop} {
      // 		margin-right: 100px;
      // 	}
      // 	@media #{$breakpoint-above-destop-wide} {
      // 		margin-right: 40%;
      // 	}
      // }
      .mt-50 {
        margin-top: 50px;
        @media #{$breakpoint-below-tablet} {
          margin-top: unset;
        }
      }
      .set-wid {
        // width: 50%;
        height: fit-content;
        padding-left: 0;
        @media #{$breakpoint-above-tablet} {
          margin-right: 93px !important;
        }
        @media #{$breakpoint-below-tablet} {
          width: 100%;
        }
        @media #{$mobile-only} {
          margin-left: 0%;
          padding-right: 0px;
        }
        @media #{$breakpoint-above-desktop} {
          margin-right: 16% !important;
        }
      }
      .title {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 64px;
        line-height: 68px;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #ffffff;
        @media #{$breakpoint-above-tablet} {
          width: 74% !important;
          margin-top: 66px !important;
        }
        @media #{$breakpoint-below-tablet} {
          font-size: 36px;
          line-height: 36px;
          text-align: center;
          display: block;
          align-items: unset;
          padding: 0 18%;
          margin-top: 0;
          @media (orientation: landscape) {
            padding: 0 35%;
          }
        }
        @media #{$breakpoint-below-desktop-above-tablet} {
          font-size: 53px;
          line-height: 55px;
          margin-top: 0;
        }
        @media #{$breakpoint-below-include-iphone5SE} {
          padding: 0 28px;
          margin-top: 0;
        }
        @media #{$breakpoint-between-mob-tablet} {
          padding: 0 33%;
          margin-top: 0;
        }
        @media #{$breakpoint-below-and-above-medium-desktop} {
          padding: 0 28px 0 0;
          margin-top: 0;
        }
        @media #{$breakpoint-above-destop-wide} {
          padding: 0 25% 0 0 !important;
          margin-top: 66px !important;
          width: auto !important;
        }
        @media #{$breakpoint-ipad-landscape} {
          @media (orientation: portrait) {
            padding: 0 35%;
            margin-top: 0;
          }
        }
        @media #{$mobile-only} {
          padding: 0 15% 0 11%;
        }
        @media #{$breakpoint-medium-desktop} {
          width: 68% !important;
        }
      }
    }
    .image-icon {
      @media #{$breakpoint-below-tablet} {
        text-align: center;
        margin-top: 30px;
        overflow-x: hidden;
      }
      img {
        @media #{$breakpoint-below-tablet} {
          width: 100%;
        }
        @media #{$mobile-only} {
          margin-left: -2%;
        }
        @media #{$breakpoint-tablet-only} {
          margin-left: 1.5%;
        }
      }
    }
  }
  .grid-container {
    @media #{$breakpoint-medium-desktop} {
      padding-left: 2.2rem;
    }
  }
}
