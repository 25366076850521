.sticky-header-banner {
  background: #fff;
  z-index: 9999;
  top: 0;
  position: sticky;
}
.setLocationData {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 9px 0;
  height: 50px;
  margin-right: 1.2%;

  @media #{$mobile-only} {
    align-items: start;
    padding: 8px 22px;
    height: auto;
    margin-right: 0.3%;
  }

  .google-location-img {
    height: 18px;
    width: 13px;
    margin-right: 10px;
    margin-top: -1px;
    @media (max-width: 580px) {
      margin-top: 3px;
      width: 13px;
      height: 16px;
    }
  }
  .setLocationText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: $Brand-Black;
    font-family: $National2Regular;
    height: 32px;
    padding-top: 5px;
    line-height: 24px;
    @media #{$breakpoint-below-iphone5} {
      width: 55% !important;
    }

    @media #{$mobile-only} {
      width: 60%;
      height: auto;
      padding-top: 0px;
      line-height: 1.5;
    }
  }

  .setLocation-button {
    height: 32px;
    font-size: 12px;
    width: auto;
    font-family: $National2Medium;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    padding: 5px 9px;
    margin-left: 23px;
    color: $white;
    background-color: $Brand-Black;

    @media #{$mobile-only} {
      height: 26px;
      width: 30% !important;
      margin: auto;
      padding: 2px 7px;
      inline-size: 220px;
    }

    &:focus-visible {
      box-shadow: none;
      background: $white;
      color: $Brand-Black;
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      .setLocation-button {
        color: $Brand-Black !important;
        background-color: $white !important;
      }
    }
  }
  .setLocation-button-in {
    background-color: $Brand-Black !important;
    color: $white !important;
  }
}
#promosRewards {
  .setLocation-button {
    min-width: 10.3rem;
    height: 2.75rem;
    padding: 0 2.5rem;
    border-radius: 1.4rem;
    font-size: 0.9rem;
    margin-left: 0px;
    color: $Brand-Black;
    box-shadow: none;
  }
}
.setLocationdivider {
  margin: 0%;
  border-top: 1px solid rgb(219, 219, 219);
}

.whiteButton.blackBorder {
  border: 1px solid rgb(73, 73, 73) !important;
  @media #{$mobile-only} {
    border: 1px solid rgb(32, 33, 36) !important;
  }
}

.location-notification {
  margin: auto;
  padding: 20px;
  font-family: $National2Regular;
  color: #494949;
  font-size: 14px;
  line-height: 24px;
  @media #{$breakpoint-below-tablet} {
    max-width: 100% !important;
  }
  .notify-alert {
    margin-bottom: 0px;
    background: #fff6e3;
    color: $Brand-Black;
    width: 580px;
    height: 78px;
    padding: 15px 150px 15px 20px;
    display: flex;
    @media #{$breakpoint-below-tablet} {
      width: 335px;
      padding: 15px 25px 15px 10px;
    }
    .location-alert-icon {
      width: 15px;
      height: 15px;
      margin: 4px 10px 4px 5px;
    }
  }
}
