.active-orders {
  justify-content: center;
  min-height: 526px;
  left: 0;
  border-radius: 0;
  background-color: $Brand-Black;
  border-bottom: 1px solid $Dark-Gray;

  .active-orders-title {
    .account-icon {
      height: 30px;
      display: block;
      margin: auto;
      width: 56px;
      background-size: contain;
    }

    .currentStatus {
      font-family: $National2Condensed;
      font-size: 44px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 1.5px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      padding-top: 11px;
      margin-bottom: 40px;

      @media #{$breakpoint-below-tablet} {
        margin-bottom: 30px;
      }
    }
  }

  .active-orders-list {
    padding-bottom: 50px;

    .active-orders-panel {
      margin: auto auto 20px auto;
      height: 244px;
      background-color: $Mashed-Potato-Gray;
      padding: 0px;
      display: flex;
      align-items: center;
      width: 780px;

      @media #{$breakpoint-above-tablet} {
        max-width: 780px;
      }
      .circle-top-left {
        height: 28%;
        width: 8%;
        max-width: 100%;
        border-radius: 50%;
        background: $Brand-Black;
        float: left;
        margin-left: -40px;
      }

      .circle-bottom-right {
        height: 28%;
        width: 8%;
        max-width: 100%;
        border-radius: 50%;
        background: $Brand-Black;
        float: right;
        margin-right: -40px;
      }

      .active-orders-details {
        width: 100%;
        .active-orders-details-info {
          // padding: 65px 0px 0px 0px;
          padding-left: 50px;
          span {
            font-family: $National2Condensed;
            font-size: 18px;
            font-style: normal;
            font-weight: bold;
            line-height: 26px;
            letter-spacing: 0.5px;
            text-align: left;
            color: $Brand-Red;
          }
        }

        .active-orders-details-panel {
          // padding: 10px 0px;
          display: flex;
          align-items: center;
          margin: auto;

          .active-orders-details-panel-info {
            padding: 10px 0px 10px 35px;
            float: left;
            white-space: nowrap;

            span {
              font-family: $National2Condensed;
              font-size: 36px;
              font-style: normal;
              font-weight: bold;
              line-height: 44px;
              letter-spacing: 1px;
              color: $Brand-Black;
              text-align: left;
              padding: 0px;
            }
            span:nth-child(3) {
              color: rgba(73, 73, 73, 1);
            }
          }

          .active-track-order-btn {
            padding-left: 0;
            float: right;

            button {
              float: right;
              border: 1.5px solid;
              border-color: $Brand-Black;
              background: $Mashed-Potato-Gray;
              color: $Brand-Black;
              font-family: $National2Medium;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              height: 44px;
              text-align: center;
              line-height: 24px;
              padding: 5px 2px;
              width: 172px;
            }
          }
        }

        .active-orders-details-add-info {
          padding-left: 50px;
          float: left;
          @media #{$breakpoint-below-tablet} {
            padding: 30px 0 0 0;
          }
          span {
            font-family: $National2Regular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 1px;
            color: $Dark-Gray;
            text-align: left;
            padding: 0px;
          }
        }
      }
    }
  }

  @media #{$breakpoint-below-tablet} {
    padding: 0px 20px !important;

    .active-orders-title {
      .account-icon {
        height: 20px;
        width: 39px;
      }

      .currentStatus {
        font-size: 24px;
        line-height: 32px;
        padding-top: 10px;
        letter-spacing: 1px;
      }
    }

    .active-orders-list {
      padding-bottom: 30px;

      .active-orders-panel {
        height: 286px;
        margin: 0px 0px 20px;
        padding: 0px;
        align-items: normal;
        display: grid;
        justify-items: center;

        .circle-top-left {
          height: 20%;
          width: 20%;
          margin: -35px;
          position: absolute;
          max-width: 100px;
        }

        .circle-bottom-right {
          height: 25%;
          width: 21%;
          margin: 265px;
          position: absolute;
          max-width: 100px;
        }

        .active-orders-details {
          // margin-top: 50px;
          padding: 0px;

          .active-orders-details-info {
            text-align: center;
            padding-top: 50px;
            padding-left: 0;
            span {
              text-align: justify;
              font-size: 16px;
              line-height: 20px;
            }
          }

          .active-orders-details-panel {
            padding: 0;
            align-items: normal;
            display: grid;

            .active-orders-details-panel-info {
              float: none;
              text-align: center;
              padding: 10px 0;

              span {
                text-align: justify;
                font-size: 20px;
                line-height: 22px;
              }
            }

            .active-track-order-btn {
              float: none;
              display: grid;
              justify-items: center;
              padding-top: 50px;

              button {
                float: none;
                width: 172px;
              }
            }
          }

          .active-orders-details-add-info {
            float: none;
            text-align: center;
            position: absolute;
            margin: -125px 0px 0px;

            span {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
