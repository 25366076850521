@mixin nutrition-serve-border {
  border: 1px solid #e3e3e3;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 10px;
  padding-left: 10px;
}
.NutritionAllergen-modal {
  @media #{$breakpoint-below-tablet} {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-max + 1;
    width: 100%;
    height: 100%;
  }

  .modal-content {
    height: auto;
    max-height: 80%;
    top: 3%;
    @media #{$breakpoint-above-medium-tablet} {
      top: 17%;
    }
    @media #{$breakpoint-above-medium-desktop} {
      top: 9%;
    }
    @media #{$breakpoint-below-tablet} {
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      max-height: unset;
      z-index: $z-index-max + 1;
    }
    @media #{$breakpoint-ipad-12-pro-portrait} {
      @media (orientation: portrait) {
        top: 10%;
        width: 580px;
        left: 11%;
      }
    }

    .modal-header {
      padding: unset;
      @media #{$mobile-only} {
        min-height: 40px;
        height: auto;
      }

      .header-title {
        font-weight: bold;
        font-size: 24px;
        margin: 0 !important;
        padding-left: 3%;
        -webkit-appearance: none;
        @media #{$breakpoint-above-medium-tablet} {
          display: none;
        }
        @media #{$mobile-only} {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0px;
          -webkit-appearance: none;
        }
        @media #{$medium-only} {
          font-size: 16px;
        }
        @media #{$breakpoint-below-iphoneX} {
          padding-left: 0px;
        }
      }
    }

    .custom-modal-close {
      right: 2.5% !important;
      position: static;
      padding-right: 2%;
      z-index: 7;
      top: 2%;
      -webkit-appearance: none;
      @media #{$mobile-only} {
        top: 4px;
        width: 8%;
        -webkit-appearance: none;
      }
      @media #{$breakpoint-iphone-size} {
        top: 12px;
        right: 3% !important;
        -webkit-appearance: none;
      }

      @media #{$breakpoint-below-include-iphone5SE} {
        top: 3px;
        right: 0.5%;
      }
      @media #{$breakpoint-iphone-11-pro-portrait} {
        top: 10px;
        right: 0.5%;
      }
      @media #{$breakpoint-iphone-six-plus} {
        top: 3.7px;
        right: 0.5%;
        width: 7%;
      }
      @media #{$medium-only} {
        top: 0.5%;
        padding-right: 1%;
        right: 0.5%;
      }
      @media #{$breakpoint-below-iphoneX} {
        top: 11px;
      }
      img {
        @media #{$breakpoint-iphone-size} {
          width: 14px !important;
        }
        @media #{$breakpoint-below-iphoneX} {
          margin-left: 50%;
        }
      }
    }

    .modal-body {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
    }
  }
  .nutrition-allergen-container-global {
    display: flex;
    flex-direction: column;

    .nutrion-allergen-image-container {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      .nutrition-allergen-card-image-global {
        border-radius: 0px;
        background-blend-mode: multiply;
        // background: linear-gradient(190.77deg, rgba(32, 33, 36, 0) 21.45%, #202124 98.01%);
        width: 80%;
        height: auto;
        top: 5.08%;
        margin: 0 auto;
        object-fit: contain;
        display: block;
        transform: scale(1);
        transition: all 0.7s linear;
        animation-name: cf3FadeInOut;
        animation-timing-function: ease-in;
        animation-duration: 0.7s;
        animation-direction: alternate;
        @media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
          height: 100%;
        }
        @media #{$breakpoint-ipad-pro-tablet} {
          height: 300px;
          width: 100%;
        }
      }
    }
    .nutrition-allergen-details-section {
      overflow: hidden;
      margin-left: 40px;
      margin-right: 40px;
      .product-details-header {
        margin-bottom: 30px;
        .product-name {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 36px;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          color: #202124;
        }
        .kj-info-details {
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: -0.5px;
          color: $gray-lightbg;
          padding-top: 7px;
        }
      }
      .nutrition-allergen-list-global {
        .nutrition-header-global {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #e3e3e3;

          .nutrition-name-global {
            font-family: $National2Condensed;
            font-size: 18px;
            font-weight: 700;
            line-height: 20px;
            text-align: left;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            align-items: center;
          }
          .nutrition-value {
            font-family: $National2Regular;
            font-size: 12px;
            font-weight: 400 !important;
            line-height: 22px;
            text-align: center;
            align-items: center;
            display: flex;

            .nutrition-value-serve {
              .per-serve {
                font-weight: 400;
                border-width: 0px;
                background-color: white;
                border-top-left-radius: 5px;
                @include nutrition-serve-border;

                &.activeServe {
                  background-color: #494949;
                  color: #ffffff;
                }
              }
            }
            #per-serve:active {
              background-color: grey;
            }
            .nutrition-value-gram {
              .per-gram {
                font-weight: 400;
                border-width: 0px;
                background-color: white;
                border-top-right-radius: 5px;
                @include nutrition-serve-border;

                &.activeGram {
                  background-color: #494949;
                  color: #ffffff;
                }
              }
            }
            #per-gram:active {
              background-color: grey;
            }
          }
        }

        .nutrition-details-global {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          row-gap: 17px;
          column-gap: 18px;
          margin-bottom: 20px;
          margin-top: 20px;
          @media #{$mobile-only} {
            column-gap: 11px;
          }
          .nutrition-name-amoumt-details {
            width: 30%;

            .nutrition-amount-global {
              font-family: $National2Condensed;
              font-style: normal;
              font-weight: 700;
              font-size: 36px;
              line-height: 36px;
              letter-spacing: 0.01rem;
              text-transform: uppercase;
              color: #202124;
              text-align: center;
            }

            .nutrition-name-unit-global {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 22px;
              display: flex;
              align-items: center;
              flex-direction: column;
              text-align: center;
            }
          }
        }

        .allergen-header-global {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #e3e3e3;
          padding-bottom: 8px;
          .allergen-name-global {
            font-family: $National2Condensed;
            font-size: 18px;
            font-weight: 700;
            line-height: 20px;
            text-align: left;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            align-items: center;
          }
        }
        .non-ala-carte-notification {
          display: flex;
          flex-direction: column;
          margin-top: 25px;
          .notification-alert {
            background: #fff6e3;
            color: #202124;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            border-radius: 4px;
            display: flex;
            padding: 15px;
            width: 100%;
            margin-bottom: 1%;
            .non-ala-carte-alert-icon {
              width: 15px;
              height: 15px;
              margin: 4px 10px 4px 5px;
            }
            .non-ala-carte-alert-text {
              display: flex;
              flex-direction: column;
              .non-ala-carte-alert-link-text {
                text-decoration: underline;
                text-underline-offset: 2px;
                .non-ala-carte-alert-link {
                  color: #202124;
                  text-decoration-skip-ink: none;
                }
              }
            }
          }
        }
        .allergen-information {
          display: flex;
          flex-direction: column;
          padding-top: 15px;
          padding-bottom: 15px;

          .allergen-text {
            font-weight: bold;
          }

          .allergen-notify-present {
            color: #202124;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            border-radius: 4px;
            display: flex;
            width: 100%;
            margin-bottom: 1%;

            .alert-icon {
              width: 15px;
              height: 15px;
              margin: 4px 10px 4px 5px;
            }
          }
          .allergen-notify-trace {
            color: #202124;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            border-radius: 4px;
            display: flex;
            width: 100%;
            margin-bottom: 1%;

            .alert-icon-trace {
              width: 15px;
              height: 15px;
              margin: 4px 10px 4px 5px;
            }
          }
        }
        .allergen-details-global {
          table {
            width: 100%;
            tr {
              height: 42px;
              td {
                border: 1px solid #e3e3e3;
              }
              .allergen-component {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                padding-left: 10px;
              }
              .allergen-icon {
                text-align: center;
                width: 45px;
                height: 10px;
                margin: 4px 10px 4px 5px;
              }
              .allergen-icon-trace {
                text-align: center;
                width: 45px;
                height: 10px;
                margin: 4px 10px 4px 5px;
              }
              .allergen-icon-text {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                height: 10px;
                line-height: 22px;
                text-align: center;
                width: 45px;
              }
            }
          }
        }
        .disclaimer-section {
          border-top: 2px solid #e3e3e3;
          border-bottom: 2px solid #e3e3e3;
          padding-top: 15px;
          padding-bottom: 15px;
          margin-top: 30px;
          .global-accordian-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            outline: none;
            .global-accordian-header-container {
              padding: unset;
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: baseline;
              text-align: left;
              background-color: transparent;
              border: none;
              cursor: pointer;
              .global-accordian-header {
                font-weight: bold;
                font-family: $National2Regular;
                font-style: normal;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #494949;
              }
            }
            .global-accordian-header-container::after {
              content: url('../../staticAssets/images/Single_Arrow_Black.svg');
            }
            .active::after {
              content: url('../../staticAssets/images/Single_Arrow_Black_Expand.svg');
            }
            .global-accordian-content {
              .disclaimer-content {
                padding: 10px;
                .protected-accordian-answer:first-child {
                  padding-left: unset;
                }
              }
            }
          }
        }
        .disclaimer-section-open {
          background-color: $Mashed-Potato-Gray;
        }
      }
    }
  }
}

.cart-indulgent-global {
  .modal-body {
    background: $white !important;
    padding: 0 !important;
  }
  .ContinueButton {
    border-top: 1px solid #dbdbdb;
    position: sticky;
    bottom: -1px;
    background: $white;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    padding-top: 23px;
    padding-bottom: 23px;

    @media #{$breakpoint-below-tablet} {
      width: 100%;
      bottom: 0;
    }
    @media #{$breakpoint-samsung-galaxy-tab-s7} {
      margin-top: 18%;
    }
  }
  .modal-content {
    @media #{$breakpoint-samsung-galaxy-tab-s7} {
      height: 100%;
    }
  }
}
.add-ons-toast {
  width: 298px;
  top: 451px;
  padding: 0px !important;

  & .addons-text-div {
    text-align: $center;
  }

  & .text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    font-family: $National2Regular;
    margin-left: 5px;
    @media #{$breakpoint-below-iphoneX} {
      font-size: 10px;
    }
  }
}
