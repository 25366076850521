@import './languageSelectorComp';

.root-header-au {
  .hamburger-container {
    // -webkit-transition: width 0.3s ease-in-out;
    // -moz-transition: width 0.3s ease-in-out;
    // -o-transition: width 0.3s ease-in-out;
    transition: width 1.5s;
    width: 0vw;
    height: 0vh;
    overflow: hidden;
  }

  .hamburger-container.side-nav-open {
    @media #{$breakpoint-above-tablet} {
      display: none;
    }
    // transition: width 0.3s;
    width: 100vw;
    background-color: $white;
    display: block;
    position: fixed;
    z-index: $z-index-max + 1;
    top: 0;
    left: 0;
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      height: 90vh;
    }
    @supports not (-webkit-touch-callout: none) {
      /* CSS for other than iOS devices */
      height: 100vh;
    }
    overflow-y: auto;

    .hamburger-header-container {
      width: 100%;
      height: 45px;
      border-bottom: 1px solid $Inactive-Gray;
      display: flex;
      position: sticky;
      top: 0;
      left: 0;
      z-index: $z-index-max + 2;
      background-color: #fff;
      justify-content: space-between;

      .hamburger-close-button {
        margin-left: 15px;
        margin-right: 15px;
        padding-right: 0px;
        outline: none;
        background-color: transparent;
        border: none;
        cursor: pointer;
        @media #{$mobile-only} {
          margin-left: 13px;
          padding-bottom: 5px;
        }

        img {
          width: 15px;
          height: 15px;
        }
      }

      .header-stripes-icon {
        margin: 0 auto;
      }

      @import './cartIconComp';
    }

    .hamburger-body-container {
      .language-head-container {
        display: flex;
        flex-direction: row;
        margin: 30px 24px 20px 20px;
        justify-content: space-between;
        @media #{$mobile-only} {
          margin: 30px 24px 15px 20px;
        }
        .hamburger-heading {
          height: 72px;
          width: 160px;
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 36px;
          text-transform: uppercase;
          @media #{$mobile-only} {
            color: rgba(0, 0, 0, 1);
          }
        }

        @include language-selector-comp-styles($withBorder: true, $color: $Brand-Black);
      }
      .sign-in-container {
        margin: 0 18px 75px 22px;
        cursor: pointer;
        .account-container {
          position: absolute;

          .msg-badge {
            position: absolute;
            top: -7px;
            left: 9px;
            background: red;
            display: flex;
            border-radius: 18px;
            color: #fff;
            font-size: 10px;
            height: 18px;
            min-width: 18px;
            justify-content: center;
            align-items: center;
          }
        }
        .account-icon {
          height: 18px;
          width: 18px;
        }
        .sign-in-text {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          display: inline;
          align-items: center;
          color: $Brand-Black;
          margin: 0 10px;
          @media #{$mobile-only} {
            text-align: right;
          }
        }
      }
      @import './menuLinksComp';
      .support-section-title {
        margin-top: 10px;
        height: 60px;
        border-bottom: 1px solid $Light-Gray;
        background-color: $Mashed-Potato-Gray;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Black;
        padding: 20px 18px 20px 22px;
      }

      .ul-title {
        border-radius: 3px;
        align-items: center;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Black;
        padding: 0 18px 5% 22px;
        .header-text-footer {
          display: none;
        }

        .global-accordian-container {
          margin-top: 10px;
          .global-accordian-header-container {
            height: 60px;
            padding: 20px 20px 20px 22px;
            border-bottom: 1px solid $Light-Gray;
            background-color: $Mashed-Potato-Gray;
          }

          .global-accordian-header {
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $Brand-Black;
          }

          .global-accordian-content {
            .footer-links {
              list-style: none;
              outline: none;
              padding-inline-start: 0px;
            }

            .footer-links > li {
              outline: none;
            }
            @media #{$mobile-only} {
              .footer-links > li {
                height: 50px;
              }
            }
            .footer-links > li > a {
              font-family: $National2Medium;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: $Dark-Gray;
              text-decoration: unset;
              white-space: nowrap;
              outline: none;
            }
          }
        }
        .footer-links {
          background-color: $Mashed-Potato-Gray;
          width: 100%;

          li:first-of-type {
            margin-top: 0;
          }

          li {
            height: 45px;
            padding: 11px 18px 11px 22px;
            margin-top: 0;
            margin-bottom: 0;
            font-family: $National2Regular;
            @media #{$mobile-only} {
              padding: 11px 18px 11px 18px;
            }
            a {
              color: $Brand-Black;
              text-transform: none;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;

              .find-A-kfc-img {
                padding-right: 6px;
                margin-top: -3px;
                padding-left: 1px;
              }
            }
          }
        }
      }
    }
  }
}
