@mixin priceContent {
  .price-content {
    position: sticky;
    width: 100%;
    bottom: 0;
    z-index: 1;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    background-color: $white;
    padding: 1% 1%;

    @media #{$breakpoint-below-tablet} {
      bottom: 10%;
    }

    & .price-content-parent {
      display: flex;
      justify-content: space-evenly;

      /* grid-column-gap: 15%; */

      /* column-gap: 15%; */
      align-items: center;
      width: 50%;
      margin: auto;

      @media #{$breakpoint-below-tablet} {
        position: fixed;
        bottom: 0;
        left: 2%;
        width: 100%;
        background-color: $white;
        display: flex;
        justify-content: space-between;
        padding: 5% 10% 5% 5%;
      }

      & .start-order-btn {
        @media #{$breakpoint-below-include-iphone5} {
          padding: 11px 26px;
        }
      }
      & .customizeCartButton {
        width: 210px;
        padding: 11px 5px;
        display: flex;
        justify-content: space-around;
        @media #{$breakpoint-below-include-iphoneX} {
          width: 180px;
        }
      }
      & .cart-food-quantity-change {
        display: inline-flex;
        align-items: center;

        & .cart-food-quantity {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $Brand-Black;
          margin-left: 12px;
          margin-right: 12px;
        }

        & .quantity-decrease,
        & .quantity-increase {
          display: flex;
          border: 1px solid #202124;
          background-color: #fff;
          padding: 0.75rem;
          height: 25px;
          width: 25px;
          font-size: 21px;
          line-height: 21px;
          justify-content: center;
          align-items: center;
          padding-top: 0.78rem;
          padding-bottom: 0.74rem;

          &:hover,
          &:active {
            background-color: $black;
            color: $white;
            border-color: $Brand-Black;
            cursor: pointer;
            outline: none;
          }

          &:disabled {
            background-color: transparent;
            color: $Inactive-Gray;
            border-color: $Inactive-Gray;
            cursor: not-allowed;
          }
        }
        & .gray-quantity-increase {
          background-image: url('../../staticAssets/images/cart-plus.svg');
          background-color: #fff;
          background-repeat: no-repeat;
          background-position: center;
          border-color: $Brand-Black;
        }
        & .quantity-decrease-disable {
          background-image: url('../../staticAssets/images/cart-minus-disabled.svg');
          outline: none;
          background-color: transparent;
          color: #dbdbdb;
          border-color: #dbdbdb;
          cursor: not-allowed;
        }
      }
    }
    & .price-content-parent-sopac {
      @media #{$breakpoint-below-tablet} {
        left: 0 !important;
      }
      & .confirmButton {
        &.pdp-button-disabled {
          color: $Dark-Gray !important;
          font-weight: bold !important;
          &:active,
          &:hover {
            border: none !important;
            color: $Dark-Gray !important;
            cursor: not-allowed !important;
            background: $Inactive-Gray !important;
          }
        }
      }
    }
  }
  .price-content-sopac {
    position: absolute;
    bottom: 0 !important;
    padding: 17px;
    @media #{$breakpoint-above-tablet} {
      border-top: 1px solid $Inactive-Gray;
    }
    .price-content-parent {
      @media #{$breakpoint-below-tablet} {
        border-top: 1px solid $Inactive-Gray;
        padding: 5%;
      }
      @media #{$mobile-only} {
        padding: 30px 20px;
        border-top: 1px solid #e1e1e1;
        .confirmButton {
          left: 8.51%;
          right: 50.42%;
          top: 32.14%;
          bottom: 32.14%;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #ffffff;
        }
      }
    }
    .confirmButton {
      margin: auto;
      display: flex;
      justify-content: space-between;
    }
    .confirmButtonWithPrice {
      width: 282px;
      @media #{$mobile-only} {
        width: 336px;
      }
      padding: 11px 20px;
    }
  }
}

@mixin modalDimensions {
  padding: 3% 10% 3% 9%;
  overflow-y: auto;
  max-height: 86%;
}
@mixin quantityActiveWhiteStateImg {
  background-image: url('../../../styles/staticAssets/images/cart-plus-white.svg');
  outline: none;
}
@mixin quantityHoverBlackStateImg {
  background-image: url('../../staticAssets/images/cart-minus-active-white.svg');
  outline: none;
}
@mixin quantityDisabledBlackStateImg {
  background-image: url('../../staticAssets/images/cart-minus-disabled.svg');
  outline: none;
}
@mixin customizeContainer {
  @media #{$breakpoint-below-tablet} {
    padding: 3% 7% 3% 7%;
    width: 100%;
  }

  & form {
    width: 100%;

    @media #{$breakpoint-below-tablet} {
      margin-top: 12px;
    }

    .accordian-container {
      border-bottom: 1px solid $Inactive-Gray;
      padding-bottom: 2%;
      margin-bottom: 2%;
      &:last-child {
        border-bottom: 0;
      }

      @media #{$breakpoint-below-tablet} {
        margin-bottom: 8%;
        padding-bottom: 10%;
      }

      .accordian-header {
        @media #{$breakpoint-below-tablet} {
          margin-left: 0;
        }
        .accordion {
          span {
            &:focus-visible {
              outline: none;
            }
          }
        }
      }

      .accordion {
        padding: 0 0 !important;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 130%;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        background-color: $white;
        color: $black;
        border-top: 0 !important;
        position: relative;

        @media #{$breakpoint-below-tablet} {
          font-size: 20px;
          line-height: 22px;
          letter-spacing: 1px;
        }
        .updatedBadge {
          font-size: 0.9rem;
          color: #fff;
          background-color: $Brand-Black;
          border-radius: 1rem;
          padding: 0 0.5rem;
          position: absolute;
          right: 2rem;
          display: inline-flex;
          top: 0.75rem;
          height: 1.5rem;
          align-items: center;
        }
      }

      .accordion::after {
        content: url('../../staticAssets/images/Single_Arrow_Black.svg');
      }

      .active::after {
        content: url('../../staticAssets/images/Single_Arrow_Black_Expand.svg');
      }

      .selected-mod {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
      }

      .panel {
        height: 48vh;
        display: none;

        .uplift-dropdown {
          position: relative;

          .language-select-uplift {
            display: flex;
            align-items: center;
            position: relative;
            border-radius: 30px !important;
            outline: none;
            width: 30%;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            background-color: $white;
            font-family: $National2Regular;
            color: $Brand-Black;
            border: 1.5px solid $Dark-Gray;
            box-sizing: border-box;
            height: 44px;
            cursor: pointer;
            padding: 0 0 0 3% !important;

            @media #{$breakpoint-below-tablet} {
              width: 95%;
              padding: 0 0 0 10% !important;
              height: 44px;
              margin-bottom: 33px;
            }

            .selected-rate {
              letter-spacing: -0.1px;
              margin-left: 5px;
            }
          }

          .dropdown-img-ham {
            position: absolute;
            width: 8px;
            height: 4px;
            right: 24px;
            left: 88%;
            top: 41.5%;
          }

          .filter-list {
            position: absolute;
            z-index: 9;
            list-style: none;
            background: $Brand-Black;
            padding: 0 0;
            padding-bottom: 10px;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1) !important;
            border-radius: 3px !important;
            font-family: $National2Regular !important;
            font-style: normal !important;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $white;
            cursor: pointer !important;
            width: 30%;
            margin-top: 1%;

            .filter-list-items {
              padding: 6px 14px;
              color: white;
              display: flex;
              justify-content: space-between;

              .rate-container {
                margin-right: 20px;
                width: 65px;

                .filter-item-rate {
                  font-size: 12px;
                  line-height: 22px;
                  margin-right: 10px;
                }

                .selected-stroke {
                  display: inline-block;
                }
              }

              &:hover {
                background-color: $Dark-Gray;
              }
            }
          }
        }

        .mobile-modal-filter-background {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 7;

          .mobile-modal-filter {
            position: absolute;
            width: 90%;
            height: 270px;
            left: 5%;
            bottom: 13%;
            z-index: $z-index-max + 1;

            @media #{$breakpoint-between-mob-tablet} {
              width: 94%;
              left: 3%;
            }

            .mobile-modal-filter-container {
              width: 100%;
              height: 212px;
              background-color: rgb(255, 255, 255);
              margin-bottom: 8px;
              border-radius: 10px;

              .mobile-filter-list {
                list-style: none;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 30px;
                padding-bottom: 32px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                .mobile-filter-list-items {
                  height: 32px;
                  width: 100%;
                  border-radius: 6px;
                  color: $Dark-Gray;
                  opacity: 0.8;
                  font-size: 20px;
                  line-height: 22px;
                  font-style: normal;
                  font-weight: normal;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .mobile-rate-container {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                  }
                }

                .mobile-filter-list-items-default {
                  height: 32px;
                  width: 100%;
                  border-radius: 6px;
                  color: $Dark-Gray;
                  opacity: 0.8;
                  font-size: 16px;
                  line-height: 22px;
                  font-style: normal;
                  font-weight: normal;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .mobile-filter-list-items-selected {
                  height: 32px;
                  width: 100%;
                  border-radius: 6px;
                  background-color: $grey-selected;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 20px;
                  line-height: 22px;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  justify-content: center;

                  .mobile-rate-container {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                  }
                }
              }
            }

            .mobile-modal-filter-button {
              background-color: rgb(255, 255, 255);
              height: 50px;
              border-radius: 10px;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
              display: flex;
              align-items: center;
              text-align: center;
              color: $blue-done;
              justify-content: center;
            }
          }
        }
        .au-modifier-list {
          flex-wrap: wrap !important;

          & .caloricInfo {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $Grey-Medium;
          }

          margin-left: 0px !important;
          margin-right: 0px !important;
          @media #{$mobile-only} {
            margin-top: 2px;
          }
        }
        .modifier-list-items {
          display: flex;
          flex-wrap: wrap;
          margin-top: 2%;
          width: 102%;
          top: 0;
          z-index: 1;

          @media #{$breakpoint-below-tablet} {
            flex-wrap: nowrap;
            margin-left: 20px;
          }
          &.modifier-list-items-in {
            @media #{$breakpoint-below-tablet} {
              margin-left: 0px !important;
            }
          }
          .modifier-item-card {
            position: relative;
            background-color: #f8f7f5;
            border-radius: 3px;
            width: 183px;
            height: 216px;
            margin-right: 15px;
            object-fit: contain;
            cursor: pointer;
            margin-bottom: 15px;

            @media #{$breakpoint-between-mob-tablet} {
              width: 32% !important;
            }

            @media #{$breakpoint-below-tablet} {
              width: 47%;
              height: 215px;
            }

            .modifier-card-image {
              width: 72%;
              height: 133px;
              position: absolute;
              right: 0;
              left: 0;
              margin: 1.56px 26.26px 5.33px 26.29px;

              @media #{$breakpoint-below-tablet} {
                width: 75%;
              }
            }

            .modifier-product-save {
              position: absolute;
              left: 78.14%;
              right: 5.46%;
              top: 4.63%;
              bottom: 81.48%;
              cursor: pointer;
            }

            & .seperator {
              background: $Light-Gray;
            }

            .modifier-card-content {
              font-family: $National2Regular;
              font-style: normal;
              border-top: 1px solid $Light-Gray;
              position: absolute;
              left: 0%;
              right: 0%;
              top: 63.43%;
              bottom: 36.11%;

              & .title {
                font-family: $National2Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $Brand-Black;
                margin-top: 11px;
                margin-left: 20px;
                margin-right: 20px;
              }

              & .caloricInfo {
                font-weight: normal;
                font-size: 12px;
                line-height: 22px;
                color: #595959;
              }
            }
          }

          .selected {
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            mix-blend-mode: normal;
            border-radius: 3px;
          }

          .slick-list {
            width: 100vw !important;

            .slick-track {
              height: 218px !important;

              .slick-slide {
                padding-right: 15px !important;
                padding-left: 5px !important;
                .carousal-modifier-item-card {
                  position: relative;
                  background-color: #f8f7f5;
                  border-radius: 3px;
                  margin-right: 15px;
                  object-fit: contain;
                  cursor: pointer;
                  width: 100%;
                  height: 215px;

                  .carousal-modifier-card-image {
                    width: 75%;
                    height: 135px;
                    position: absolute;
                    left: 10.38%;
                    right: 15.85%;
                    top: 1.39%;
                    bottom: 36.11%;
                  }

                  .carousal-modifier-product-save {
                    position: absolute;
                    left: 74.14%;
                    right: 5.46%;
                    top: 5.63%;
                    bottom: 81.48%;
                    cursor: pointer;
                  }

                  .modifier-card-content {
                    font-family: $National2Regular;
                    font-style: normal;
                    border-top: 1px solid $Light-Gray;
                    position: absolute;
                    left: 0%;
                    right: 0%;
                    top: 63.43%;
                    bottom: 36.11%;

                    & .title {
                      font-weight: bold;
                      font-size: 16px;
                      line-height: 24px;
                      color: $black;
                      margin-top: 14px;
                      margin-left: 12px;
                      margin-right: 12px;
                    }

                    & .caloricInfo {
                      font-weight: normal;
                      font-size: 12px;
                      line-height: 22px;
                      color: #595959;
                    }
                  }
                }

                .selected {
                  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                  mix-blend-mode: normal;
                  border-radius: 3px;
                }
              }
            }
          }
        }
      }

      .panel-active {
        display: inline !important;
      }
    }
  }
}

.product-listing-module-modal,
.product-listing-module-modal-sopac {
  .accordion {
    margin-bottom: 3px;
  }

  @media #{$breakpoint-below-tablet} {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-max + 1;
    width: 100%;
    height: 100%;
  }

  & .modal-content {
    position: absolute;
    width: 80%;
    height: 80%;
    left: 10%;
    top: 10%;

    @media #{$breakpoint-above-desktop} {
      left: 50%;
      margin-left: -590px;
      max-width: 1180px;
    }

    @media #{$breakpoint-below-tablet} {
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 111%;
      z-index: $z-index-max + 1;
    }

    .modal-header {
      .header-title {
        margin: 0 !important;
        display: flex;
        align-items: center;
        padding-left: 3%;

        @media #{$breakpoint-below-tablet} {
          padding-left: 0 !important;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $Dark-Gray;
          text-transform: capitalize;
        }
      }

      @media #{$breakpoint-below-tablet} {
        justify-content: center;
      }

      .custom-modal-close {
        @media #{$breakpoint-below-tablet} {
          right: 1.5% !important;
          top: 1.5%;
        }
        @media #{$breakpoint-above-medium-desktop} {
          right: 2%;
          top: 31px;
        }
        @media #{$breakpoint-above-destop-wide} {
          top: 38px;
        }
      }
    }

    & .modal-body {
      background: $white;
      padding: 0;
      overflow-y: hidden !important;

      @media #{$breakpoint-below-tablet} {
        overflow-y: hidden;
        height: 100vh;
      }

      & .modal-body-row {
        @include modalDimensions();
        @include customizeContainer();

        .modifier-list-items {
          @media #{$breakpoint-below-tablet} {
            margin-left: 0px !important;
          }
        }

        & .form .product-list-option {
          font-size: 0;
        }
      }

      @include priceContent();
    }
  }
}
@mixin customizeContainerSopac {
  @media #{$breakpoint-below-tablet} {
    padding: 3% 0%;
    width: 100%;
  }

  & form {
    width: 100%;

    @media #{$breakpoint-below-tablet} {
      margin-top: 12px;
    }

    .accordian-container {
      border-bottom: 1px solid $Inactive-Gray;
      padding-bottom: 2%;
      margin-bottom: 2%;
      .accordian-container-sopac {
        border-bottom: 1px solid $Inactive-Gray;
        padding-bottom: 2%;
        margin-bottom: 30px;
      }
      &:last-child {
        border-bottom: 0;
      }

      @media #{$breakpoint-below-tablet} {
        margin-bottom: 4%;
        padding-bottom: 4%;
      }

      .accordian-header {
        @media #{$breakpoint-below-tablet} {
          margin: 0 20px;
        }
        .accordion {
          span {
            &:focus-visible {
              outline: none;
            }
          }
        }
      }

      .accordion {
        padding: 0 0 !important;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 130%;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        background-color: $white;
        color: $black;
        border-top: 0 !important;
        position: relative;
        display: flex;
        justify-content: space-between;

        @media #{$breakpoint-below-tablet} {
          font-size: 20px;
          line-height: 22px;
          letter-spacing: 1px;
        }
        .badgeContainer {
          position: absolute;
          right: 4rem;
          display: inline;
          bottom: 0;
          @media #{$breakpoint-below-tablet} {
            position: static;
          }
          @media #{$mobile-only} {
            width: 82%;
            padding-left: 12px;
          }
        }
        .textbadgecontainer {
          display: inline;
          h3 {
            font-size: 26px;
            @media #{$breakpoint-below-tablet} {
              font-size: 20px;
            }
            @media #{$mobile-only} {
              display: inline-flex;
            }
          }
        }
        .updatedBadge {
          font-size: 0.9rem;
          color: $white;
          background-color: $Brand-Black;
          border-radius: 1rem;
          padding: 0 0.5rem;
          position: absolute;
          right: 2rem;
          display: inline-flex;
          top: 0.75rem;
          height: 1.5rem;
          align-items: center;
        }
        .updatedBadge-sopac {
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 1px;
          color: $white;
          background-color: $Brand-Black;
          border-radius: 1rem;
          padding: 0 11px;
          display: inline-flex;
          top: 0.75rem;
          height: 1.5rem;
          align-items: center;
          margin-right: 4px;

          @media #{$breakpoint-above-medium-tablet} {
            margin-top: 22px;
          }
        }
        @media #{$mobile-only} {
          .maxBadge,
          .updatedBadge-sopac {
            height: 1.25rem !important;
            border-radius: 1.875rem !important;
          }
        }
        .maxBadge {
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 1px;
          color: $Brand-Black;
          border: solid 1px $Brand-Black;
          background-color: #fff;
          border-radius: 1rem;
          padding: 0 11px;
          display: inline-flex;
          top: 0.75rem;
          height: 1.5rem;
          align-items: center;
          margin-right: 10px;
        }
      }

      .accordion::after {
        content: url('../../staticAssets/images/Single_Arrow_Black.svg');
        @media #{$mobile-only} {
          padding-top: 15px;
        }
      }

      .active::after {
        content: url('../../staticAssets/images/Single_Arrow_Black_Expand.svg');
      }

      .selected-mod {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
      }

      .panel {
        height: 48vh;
        display: none;
        .cart-food-quantity-change {
          justify-content: space-between;
          .quantity-increase,
          .quantity-decrease {
            background-color: $Brand-Red;
            border-color: $Brand-Red;

            &:active {
              background-color: transparent;
              color: $white;
              border-color: $Brand-Black;
              cursor: pointer;
              outline: none;
            }

            &:disabled {
              background-color: transparent;
              color: $Inactive-Gray;
              border-color: $Inactive-Gray;
              cursor: not-allowed;
            }
          }
          .quantity-increase {
            @include quantityActiveWhiteStateImg();
            &:hover:enabled {
              @include quantityActiveWhiteStateImg();
              background-color: $black;
              border-color: $Brand-Black;
            }
            &:disabled {
              background-image: url('../../staticAssets/images/cart-plus-disabled.svg');
              border: 1px solid $Inactive-Gray;
              background-repeat: no-repeat;
              background-position: center;
              opacity: 1;
            }
          }
          .quantity-decrease {
            @include quantityHoverBlackStateImg();
            &:hover:enabled {
              @include quantityHoverBlackStateImg();
              border: 1px solid $Brand-Black;
              background-color: $black;
            }
            &:disabled {
              @include quantityDisabledBlackStateImg();
              &:hover {
                @include quantityDisabledBlackStateImg();
                border: 1px solid $Inactive-Gray;
              }
            }
          }
          .gray-quantity-increase {
            background-image: url('../../staticAssets/images/cart-plus.svg');
            background-color: #fff;
            background-repeat: no-repeat;
            background-position: center;
            border-color: $Brand-Black;
          }
          .quantity-decrease-disable {
            background-image: url('../../staticAssets/images/cart-minus-disabled.svg');
            outline: none;
            background-color: transparent;
            color: #dbdbdb;
            border-color: #dbdbdb;
            cursor: not-allowed;
          }
        }
        .retail-item-qty {
          width: 120px;
          @media #{$breakpoint-below-tablet} {
            width: 185px;
          }
        }

        .uplift-dropdown {
          position: relative;

          .language-select-uplift {
            display: flex;
            align-items: center;
            position: relative;
            border-radius: 30px !important;
            outline: none;
            width: 30%;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            background-color: $white;
            font-family: $National2Regular;
            color: $Brand-Black;
            border: 1.5px solid $Dark-Gray;
            box-sizing: border-box;
            height: 44px;
            cursor: pointer;
            padding: 0 0 0 3% !important;

            @media #{$breakpoint-below-tablet} {
              width: 95%;
              padding: 0 0 0 10% !important;
              height: 44px;
              margin-bottom: 33px;
            }

            .selected-rate {
              letter-spacing: -0.1px;
              margin-left: 5px;
            }
          }

          .dropdown-img-ham {
            position: absolute;
            width: 8px;
            height: 4px;
            right: 24px;
            left: 88%;
            top: 41.5%;
          }

          .filter-list {
            position: absolute;
            z-index: 9;
            list-style: none;
            background: $Brand-Black;
            padding: 0 0;
            padding-bottom: 10px;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1) !important;
            border-radius: 3px !important;
            font-family: $National2Regular !important;
            font-style: normal !important;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $white;
            cursor: pointer !important;
            width: 30%;
            margin-top: 1%;

            .filter-list-items {
              padding: 6px 14px;
              color: white;
              display: flex;
              justify-content: space-between;

              .rate-container {
                margin-right: 20px;
                width: 65px;

                .filter-item-rate {
                  font-size: 12px;
                  line-height: 22px;
                  margin-right: 10px;
                }

                .selected-stroke {
                  display: inline-block;
                }
              }

              &:hover {
                background-color: $Dark-Gray;
              }
            }
          }
        }

        .mobile-modal-filter-background {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 7;

          .mobile-modal-filter {
            position: absolute;
            width: 90%;
            height: 270px;
            left: 5%;
            bottom: 13%;
            z-index: $z-index-max + 1;

            @media #{$breakpoint-between-mob-tablet} {
              width: 94%;
              left: 3%;
            }

            .mobile-modal-filter-container {
              width: 100%;
              height: 212px;
              background-color: rgb(255, 255, 255);
              margin-bottom: 8px;
              border-radius: 10px;

              .mobile-filter-list {
                list-style: none;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 30px;
                padding-bottom: 32px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                .mobile-filter-list-items {
                  height: 32px;
                  width: 100%;
                  border-radius: 6px;
                  color: $Dark-Gray;
                  opacity: 0.8;
                  font-size: 20px;
                  line-height: 22px;
                  font-style: normal;
                  font-weight: normal;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .mobile-rate-container {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                  }
                }

                .mobile-filter-list-items-default {
                  height: 32px;
                  width: 100%;
                  border-radius: 6px;
                  color: $Dark-Gray;
                  opacity: 0.8;
                  font-size: 16px;
                  line-height: 22px;
                  font-style: normal;
                  font-weight: normal;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .mobile-filter-list-items-selected {
                  height: 32px;
                  width: 100%;
                  border-radius: 6px;
                  background-color: $grey-selected;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 20px;
                  line-height: 22px;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  justify-content: center;

                  .mobile-rate-container {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                  }
                }
              }
            }

            .mobile-modal-filter-button {
              background-color: rgb(255, 255, 255);
              height: 50px;
              border-radius: 10px;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
              display: flex;
              align-items: center;
              text-align: center;
              color: $blue-done;
              justify-content: center;
            }
          }
        }
        .au-modifier-list {
          flex-wrap: wrap !important;

          & .caloricInfo {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $Grey-Medium;
          }

          margin-left: 0px !important;
          margin-right: 0px !important;
          @media #{$mobile-only} {
            margin-top: 2px;
          }
        }
        .modifier-list-items {
          display: flex;
          flex-wrap: wrap;
          margin-top: 2%;
          top: 0;
          z-index: 1;

          @media #{$breakpoint-below-tablet} {
            flex-wrap: wrap;
            margin-left: 20px;
            margin-right: 20px;
          }

          .modifier-item-card {
            position: relative;
            background-color: #f8f7f5;
            border-radius: 3px;
            width: 183px;
            height: 216px;
            margin-right: 15px;
            object-fit: contain;
            cursor: pointer;
            margin-bottom: 15px;

            @media #{$breakpoint-between-mob-tablet} {
              width: 32% !important;
            }

            @media #{$breakpoint-below-tablet} {
              width: 47%;
              height: 215px;
            }

            .modifier-card-image {
              width: 72%;
              height: 133px;
              position: absolute;
              right: 0;
              left: 0;
              margin: 1.56px 26.26px 5.33px 26.29px;

              @media #{$breakpoint-below-tablet} {
                width: 75%;
              }
            }

            .modifier-product-save {
              position: absolute;
              left: 78.14%;
              right: 5.46%;
              top: 4.63%;
              bottom: 81.48%;
              cursor: pointer;
            }

            & .seperator {
              background: $Light-Gray;
            }

            .modifier-card-content {
              font-family: $National2Regular;
              font-style: normal;
              border-top: 1px solid $Light-Gray;
              position: absolute;
              left: 0%;
              right: 0%;
              top: 63.43%;
              bottom: 36.11%;

              & .title {
                font-family: $National2Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $Brand-Black;
                margin-top: 11px;
                margin-left: 20px;
                margin-right: 20px;
              }

              & .caloricInfo {
                font-weight: normal;
                font-size: 12px;
                line-height: 22px;
                color: #595959;
              }
            }
          }

          .selected {
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            mix-blend-mode: normal;
            border-radius: 3px;
          }

          .slick-list {
            width: 100vw !important;

            .slick-track {
              height: 218px !important;

              .slick-slide {
                padding-right: 15px !important;
                padding-left: 5px !important;
                .carousal-modifier-item-card {
                  position: relative;
                  background-color: #f8f7f5;
                  border-radius: 3px;
                  margin-right: 15px;
                  object-fit: contain;
                  cursor: pointer;
                  width: 100%;
                  height: 215px;

                  .carousal-modifier-card-image {
                    width: 75%;
                    height: 135px;
                    position: absolute;
                    left: 10.38%;
                    right: 15.85%;
                    top: 1.39%;
                    bottom: 36.11%;
                  }

                  .carousal-modifier-product-save {
                    position: absolute;
                    left: 74.14%;
                    right: 5.46%;
                    top: 5.63%;
                    bottom: 81.48%;
                    cursor: pointer;
                  }

                  .modifier-card-content {
                    font-family: $National2Regular;
                    font-style: normal;
                    border-top: 1px solid $Light-Gray;
                    position: absolute;
                    left: 0%;
                    right: 0%;
                    top: 63.43%;
                    bottom: 36.11%;

                    & .title {
                      font-weight: bold;
                      font-size: 16px;
                      line-height: 24px;
                      color: $black;
                      margin-top: 14px;
                      margin-left: 12px;
                      margin-right: 12px;
                    }

                    & .caloricInfo {
                      font-weight: normal;
                      font-size: 12px;
                      line-height: 22px;
                      color: #595959;
                    }
                  }
                }

                .selected {
                  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                  mix-blend-mode: normal;
                  border-radius: 3px;
                }
              }
            }
          }
        }
      }

      .panel-active {
        display: inline !important;
      }
    }
  }
}

.pdp-custm-sopac {
  @include customizeContainerSopac();
  @include priceContent();
}
.product-listing-module-modal-sopac {
  & .modal-content {
    .modal-header {
      @media #{$mobile-only} {
        background-color: $white;
        .header-title {
          font-weight: 700;
          text-align: center;
          letter-spacing: 1px;
        }
        .custom-modal-close {
          top: 12px;
          right: 9px !important;
        }
        .custom-modal-close img {
          width: 70%;
        }
      }
    }
    & .modal-body {
      .modal-body-row {
        @include customizeContainerSopac();
      }
      @media #{$mobile-only} {
        .modal-body-row form {
          margin-top: 7px;
          .accordian-container {
            padding-bottom: 7.3%;
            .sopac-modifier {
              border-radius: 4px;
              .sopac-modifier-customizeblock {
                padding: 9px 0;

                .cart-food-quantity-change .quantity-decrease,
                .cart-food-quantity-change .quantity-increase {
                  height: 30px;
                  width: 30px;
                }
              }
            }
            .selected-mod {
              padding-top: 5px !important;
            }
          }
        }
      }
    }
  }
}

.pdp-custm {
  @include customizeContainer();
  @include priceContent();
}

.err-toast {
  width: 100%;
  min-height: 54px;
  background: #fff6e3;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  @media #{$breakpoint-above-destop-below-large-desktop} {
    right: 40px;
  }
  @media #{$breakpoint-ipad-mini-portrait} {
    width: 320px !important;
    left: 4% !important;
  }
  @media #{$breakpoint-samsung-galaxy-tab-s7} {
    width: 320px !important;
    left: -30% !important;
  }
  @media #{$breakpoint-below-tablet} {
    width: 100% !important;
    height: auto;
    bottom: 100px;
    left: auto;
    .img-text-div {
      display: flex;
      img {
        width: 15px;
        height: 15px;
        margin: 2px 8px 0 7px;
      }
    }
  }
  @media #{$mobile-only} {
    width: 100%;
    bottom: 138px;
    min-width: 345px !important;
    left: 1% !important;
    .img-text-div img {
      margin: 2px 8px 0 7px;
    }
  }
  @media #{$breakpoint-below-desktop-above-tablet} {
    left: 4%;
    bottom: 100px;
  }
  @media #{$breakpoint-between-tablet-desktop} {
    left: 5%;
    bottom: 100px;
  }
  .img-text-div {
    .text {
      color: $Brand-Black;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      @media #{$mobile-only} {
        line-height: 22px;
        text-align: left;
      }
    }
  }
}
/* Style the category cards */
.sopac-modifier {
  width: 90%;
  background: $Mashed-Potato-Gray;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding: 0 4%;
  cursor: pointer;
  align-items: center;
  border-radius: 4px;
  @media #{$breakpoint-above-tablet} {
    height: 7.5vw;
  }
  @media #{$breakpoint-below-tablet} {
    display: block;
    padding: 0;
    margin-left: 20px;
    margin-right: 20px;
  }
  .quantity-input {
    width: 60px;
    height: 48px;
    border: 1px solid $Inactive-Gray;
    font-weight: 700;
    font-size: 38px;
    line-height: 43px;
  }

  & .sopac-modifier-card {
    background: $Mashed-Potato-Gray;
    border-radius: 3px;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @media #{$breakpoint-below-tablet} {
      padding-left: 6%;
      padding-right: 2%;
    }

    @media #{$mobile-only} {
      padding: 2px 5px 2px 20px;
      line-height: 22px;
    }

    & .sopac-modifier-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      @media #{$breakpoint-below-tablet} {
        height: 25vw;
      }
      @media #{$mobile-only} {
        height: 100px;
      }
    }

    & .sopac-modifier-card-name {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      color: $black;
      flex-direction: column;
      @media #{$breakpoint-below-tablet} {
        margin-left: 15px;
      }
      .caloricInfo {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $Grey-Medium;
        margin-right: 2px;
      }
      .calorificWithPrice {
        &::before {
          content: '•';
        }
      }
    }
    & .sopac-modifier-card-tick {
      margin-right: 4%;
      width: 38px;
      height: 38px;
      @media #{$mobile-only} {
        margin-right: 0;
      }
    }

    & .sopac-modifier-card-image {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
      margin-right: 4%;
      width: 7vw;
      @media #{$breakpoint-below-tablet} {
        width: 25vw;
        margin: 0;
      }
      @media #{$mobile-only} {
        width: 100px;
      }
    }
  }

  & .sopac-modifier-customizeblock {
    display: flex;
    justify-content: center;
    padding: 20px 0 0 5px;
    @media #{$breakpoint-below-tablet} {
      border-top: 1px solid $Inactive-Gray;
    }
    @media #{$mobile-only} {
      padding: 15px 0;
      .customize-btn .customize-icon {
        padding-left: 10px !important;
      }
    }
    & .customize-btn {
      padding: 8px 14px;
      padding-top: 11px;
      height: 35px;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 22px;
      background-color: transparent;
      border: 1px solid $Dark-Gray;
      display: flex;
      &:enabled {
        color: $Brand-Black;
      }

      &:disabled {
        border: 1px solid $Light-Gray;
        pointer-events: none;
      }

      & .customize-icon {
        padding-left: 15px;
        padding-bottom: 5px;
        display: inline-block !important;
      }
      & .disabled-icon {
        opacity: 0.5;
      }
      @media #{$mobile-only} {
        height: 32px;
        padding-top: 5px;
      }
    }
  }
  & .modgrp-greyout {
    .cart-food-quantity-change {
      .quantity-increase {
        background-color: #f8f7f5;
        border: 1px solid #dbdbdb;
        background-image: url('../../staticAssets/images/cart-plus-disabled.svg') !important;
        &:hover {
          border: 1px solid $Inactive-Gray;
        }
      }
    }
  }
}

.mod-group-sub-header {
  @media #{$breakpoint-below-tablet} {
    padding-top: 10px !important;
  }
  @media #{$mobile-only} {
    padding-top: 5px !important;
  }
}
.pdp-custm-container.mobile {
  .accordion {
    .updatedBadge {
      position: absolute;
    }
  }
}
.sopac-modifier-deactive {
  mix-blend-mode: normal;
  border: 1.5px solid #ff2e00;
  opacity: 0.6;
  cursor: not-allowed !important;
  & .unavailable-container {
    display: flex;
    & .unavailable-text {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #494949;
    }
    & .unavailable-icon {
      margin-right: 5px;
    }
  }
}
.accordian-container-highlight {
  outline: none;
  border-color: #ff2e00;
  box-shadow: 0 0 5px #ff2e00;
}
.pdp-promo-toast-container {
  //width: 430px !important;
  color: #202124;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  @media #{$breakpoint-ipad-12-pro-portrait} {
    left: 6%;
  }

  .img-text-container {
    text-align: left;
    display: flex;
  }
  .image {
    margin-right: 10px;
    margin-left: 5px;
  }
}
