.terms-desktop-container {
  display: flex;
  padding-left: 104px;
  padding-right: 130px;

  @media #{$breakpoint-above-desktop} {
    padding-left: 143px;
  }

  .terms-section-left {
    min-width: 280px;

    .terms-header-container {
      .terms-icon {
        margin-bottom: 36px;
      }

      .terms-header {
        margin-bottom: 30px;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 43px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Black;
      }

      .terms-and-condition-data {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
        width: 280px;
        //height: 240px;
      }
    }
  }

  .terms-section-right {
    margin-left: 120px;
    width: 780px;
    margin-top: 70px;

    @media #{$breakpoint-above-desktop} {
      margin-left: 288px;
      width: 1000px;
    }

    .terms-questions-desktop {
      margin-bottom: 120px;

      .terms-accordian {
        padding-top: 50px;
        padding-bottom: 50px;
        border-bottom: 2px solid $Light-Gray;

        .global-accordian-header-container {
          padding-left: 30px;
          padding-right: 44px;

          .global-accordian-header {
            font-family: $National2Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: $Brand-Black;
          }

          .global-accordian-arrow {
            width: 10px;
            height: 10px;
          }
        }

        .global-accordian-content {
          margin-top: 50px;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $Dark-Gray;
          padding-left: 30px;
          padding-right: 70px;

          .terms-accordian-answer > p > b {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $Dark-Gray;
          }

          .terms-accordian-answer > ul {
            padding-left: 14px;
          }
        }
      }

      .terms-accordian-open {
        background-color: $Mashed-Potato-Gray;
      }
    }
    .terms-questions-desktop:nth-of-type(1) {
      border-top: 2px solid $Light-Gray;
    }
  }
}

.terms-mobile-container {
  .terms-mobile-header {
    margin-bottom: 40px;
    padding-right: 20px;
    padding-left: 20px;

    .terms-icon {
      margin-bottom: 20px;
      max-width: 40px;
    }

    .terms-header {
      width: 334px;
      height: 32px;
      margin-bottom: 20px;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $Brand-Black;
    }

    .terms-and-condition-data {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      width: 335px;
      //height: 216px;
    }
  }

  p:last-child {
    margin-top: 30px;
  }

  u::after {
    content: '\a';
    white-space: pre;
  }

  .terms-description > p > u {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $Brand-Black;
    cursor: pointer;
  }

  .terms-mobile-questions {
    margin-bottom: 60px;

    .terms-accordian {
      padding-top: 36px;
      padding-bottom: 36px;
      border-top: 2px solid $Light-Gray;
      padding-right: 20px;
      padding-left: 13px;

      .global-accordian-header-container {
        .global-accordian-header {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: $Brand-Black;
        }
      }

      .global-accordian-content {
        margin-top: 24px;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
        padding-left: 5px;

        .terms-accordian-answer > p > b {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: $Brand-Black;
        }

        .terms-accordian-answer > ul {
          padding-left: 14px;
        }
      }
    }

    .terms-accordian:last-child {
      border-bottom: 2px solid $Light-Gray;
    }

    .terms-accordian-open {
      background-color: $Mashed-Potato-Gray;
    }
  }
}
#removeLine {
  border-top: none;
}
.terms-au {
  @media #{$breakpoint-above-tablet} {
    padding-left: 130px;
    padding-right: 130px;
  }
  .terms-section-right
    .terms-questions-desktop.terms-accordian
    .global-accordian-header-container.global-accordian-arrow {
    width: 15px;
    height: 15px;
  }
  .global-accordian-container .global-accordian-header-container .global-accordian-arrow {
    width: 15px;
    height: 15px;
  }
  @media #{$breakpoint-ipad-12-pro-portrait} {
    padding-right: 56px;
  }
  .terms-section-right {
    @media #{$breakpoint-ipad-12-pro-portrait} {
      margin-left: 26px;
    }
  }
}
