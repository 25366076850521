@mixin quantitiyPlusButton {
  & .quantity-increase {
    display: flex;
    border: 1px solid $Brand-Black;
    background-color: $white;
    padding: 0px;
    height: 32px;
    width: 32px;
    justify-content: center;
    background-image: url('../../staticAssets/images/cart-plus.svg');
    background-repeat: no-repeat;
    background-position: center;

    &:disabled {
      background-color: transparent;
      color: $Inactive-Gray;
      border-color: $Brand-Black;
      cursor: not-allowed;
      opacity: 0.2;
      background-image: url('../../staticAssets/images/cart-plus.svg');
    }
  }
}
@mixin cartSummaryErrorContainer {
  background: #fff4f2;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}

@mixin cartMainImage {
  width: 90% !important;
  margin: 0 auto;
  display: block;
}

@mixin storeUunavailaleCartPage {
  height: 54px;
  padding: 15px 0px 15px 20px;
  background: #fff4f2;
  border-radius: 4px;
  display: flex;
  font-family: $National2Regular;
}

@mixin cartSummaryUnavailableErrorMessage {
  margin: 2px 0 0 10px;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #202124;
}

@mixin quantitiyMinusButton {
  & .quantity-decrease {
    display: flex;
    border: 1px solid $Brand-Black;
    background-color: $white;
    padding: 0px;
    height: 32px;
    width: 32px;
    justify-content: center;
    background-image: url('../../staticAssets/images/cart-minus-active.svg');
    background-repeat: no-repeat;
    background-position: center;

    &:hover,
    &:active {
      background-color: $black;
      color: $white;
      border-color: $Brand-Black;
      cursor: pointer;
      outline: none;
      background-image: url('../../staticAssets/images/cart-minus-active-white.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &:disabled {
      background-color: transparent;
      color: $Inactive-Gray;
      border-color: $Inactive-Gray;
      cursor: not-allowed;
      background-image: url('../../staticAssets/images/cart-minus-disabled.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.cart-page-container-desktop {
  background-color: $white;

  .cart-header-container-desktop {
    margin-bottom: 50px;
    @media #{$breakpoint-below-desktop-above-tablet} {
      padding-left: 5%;
      padding-right: 5%;
    }
    @media #{$breakpoint-medium-desktop} {
      padding-left: 2%;
      padding-right: 2%;
    }

    .cart-header {
      //margin: 0 7.5%;

      .cart-header-text {
        margin-top: 11px;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 44px;
        line-height: 48px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $Brand-Black;
      }
    }
  }

  .cart-content-container-desktop {
    display: flex;
    //margin: 0 7.5%;
    @media #{$breakpoint-below-desktop-above-tablet} {
      padding-left: 5%;
      padding-right: 5%;
    }
    @media #{$breakpoint-medium-desktop} {
      padding-left: 2%;
      padding-right: 2%;
    }
    .cart-content-foodItemList {
      width: 100%;
    }

    .cart-food-container {
      //display: flex;
      //flex-direction: column;
      //width: 100%;
      padding-bottom: 30px;

      & .overlimit-error {
        // background-color: red;
        height: 54px;
        @include cartSummaryErrorContainer();
        justify-content: center;
        align-items: center;
      }

      & .store-unavailale-cart-page {
        @include storeUunavailaleCartPage;

        & .cart-summary-error-message {
          @include cartSummaryUnavailableErrorMessage;
        }
      }

      & .cart-highlight {
        border: 1px solid $Red-Orange;
      }

      .cart-discount-errors {
        display: flex;
        flex-direction: column;
        .cart-food-discount-lines {
          margin-bottom: 10px;
          .discount-error {
            background: #fff4f2;
            border-radius: 4px;
            width: 100%;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: #ff2e00;
            padding: 15px 2.7%;
            img.discount-error-icon {
              margin-right: 1.3%;
            }
          }
        }
        .cart-food-discount-lines:last-of-type {
          margin-bottom: 0;
        }
      }

      .global-accordian-container {
        border: none;
        margin-bottom: 5px;
        min-height: 46px;

        &:focus {
          outline: none;
        }

        .global-accordian-header-container {
          padding: 19px;
          padding-top: 0;
          padding-left: 0;
          height: 30px;
        }

        .global-accordion-subheader {
          padding-left: 15px;
          margin: 0;
        }
      }

      .cart-food-item-container {
        margin-top: 20px;
        height: fit-content;
        display: flex;
        //padding: 20px 16px;
        padding: 11px 16px;
        background-color: $Mashed-Potato-Gray;
        border-radius: 3px;
        //align-items: flex-start;
        align-items: center;
        width: 100%;

        .cart-food-image {
          width: 144px;
        }
        .cart-image-container {
          width: 150px;
          @include backgroundImage();
          img {
            @include cartMainImage();
            @include imageAnimation;
            @media #{$breakpoint-above-tablet} {
              &:hover {
                transform: scale(1.1);
                transition: all 0.7s linear;
              }
            }
          }
        }
        .cart-food-image,
        .cart-image-container {
          border-radius: 3px;
          object-fit: contain;
          min-height: 80px;
          overflow: hidden;
          // cursor: pointer;
        }

        .outofstock {
          opacity: 0.4;
        }

        .outofstock-text {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $Red-Orange;
          margin: 8%;
          width: 100px;
          position: absolute;
          right: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .outoftext-span {
            padding-top: 2px;
          }

          .location-alert-icon {
            margin-left: 5px !important;
          }
        }

        .cart-food-content-parent {
          margin-left: 6.5%;
          width: 100%;
          position: relative;
        }

        .cart-food-quantity-price {
          display: flex;
          align-items: center;
          position: absolute;
          right: 7%;
          bottom: 38%;
        }

        .empty-mods-quantity-price {
          bottom: 22%;
        }

        .coupon-item-quantity-price {
          bottom: 0%;
        }

        .cart-action-price-quantity {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .cart-food-content {
          margin-bottom: 10px;
          //margin-left: 30px;
          //width: 50%;

          .cart-food-name {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $Brand-Black;
            margin: 4px 0;
          }

          .cart-food-modifier {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: $Dark-Gray;
            margin: 4px 0;
            width: 90%;

            ul {
              padding-left: 15px;
            }
          }
        }

        .cart-food-edit {
          display: inline-block;
          margin-right: 20px;
        }

        .cart-food-remove,
        .cart-food-edit {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 22px;
          text-decoration-line: underline;
          color: $Brand-Black;
          cursor: pointer;
          border-radius: 3px;

          &:hover {
            background-color: $Light-Gray;
          }

          &:active {
            background: linear-gradient(0deg, rgba(32, 33, 36, 0.1), rgba(32, 33, 36, 0.1)), #e3e3e3;
          }
        }

        .cart-food-quantity-change {
          display: flex;
          align-items: center;
          margin-right: 20px;

          .cart-food-quantity {
            font-family: $National2Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            color: $Brand-Black;
            width: 40px;
            text-align: center;
          }

          @include quantitiyPlusButton();
          @include quantitiyMinusButton();
        }

        .cart-food-price {
          width: 4vw;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: right;
          color: $Brand-Black;
          display: flex;
          flex-direction: column;

          & .modified-price {
            color: $Red-Orange;
          }

          & .old-price {
            text-decoration: line-through;
          }
        }
      }

      .cart-food-remove-container {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-top: 20px;

        .cart-food-remove-all {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          text-decoration-line: underline;
          cursor: pointer;
          color: $Brand-Black;

          &:hover {
            background-color: $Light-Gray;
          }

          &:active {
            background: linear-gradient(0deg, rgba(32, 33, 36, 0.1), rgba(32, 33, 36, 0.1)), #e3e3e3;
          }
        }

        .add-more-menu {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    .cart-summary-card {
      box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      background: #ffff;
      margin-left: 40px;
      padding: 40px 40px;
      height: fit-content;

      .cart-summary-item-count {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        columns: $Brand-Black;
        margin-bottom: 20px;
      }

      .cart-summary-coupons {
        margin-bottom: 10px;
      }

      .cart-summary-content {
        border-bottom: 1px solid $Light-Gray;
        padding-bottom: 20px;
        margin-bottom: 20px;

        .cart-summary-discount-lines {
          .discount-error {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: #ff2e00;
            display: $d-flex;
            width: 272px;
            img.discount-error-icon {
              width: 15px;
              height: 15px;
              margin: 1.5% 3.6% auto 0;
            }
          }
        }

        .cart-summary-content-item {
          display: flex;
          justify-content: space-between;

          .cart-summary-content-item-name {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: $Dark-Gray;
            width: 208px;
            text-transform: capitalize;

            .cart-discount-code {
              white-space: nowrap;
              font-weight: 500;
              margin-right: 5%;
            }

            .cart-discount-clear-icon {
              vertical-align: middle;
              cursor: pointer;
            }
          }

          .cart-summary-content-item-value {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $Brand-Black;
          }
        }
      }

      .cart-carry-bag-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $Light-Gray;

        .cart-carry-bag-checkbox {
          margin-right: 12px;
        }

        .cart-carry-bag-header {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $Brand-Black;
          margin-bottom: 0;
        }
      }

      .cart-add-hope-container {
        display: flex;
        margin-bottom: 20px;
        border: 1px solid $Light-Gray;
        border-radius: 3px;
        padding: 10px 16px;

        .cart-add-hope-checkbox {
          margin-top: 5px;
          margin-right: 7px;

          &:checked {
            background-color: $black;
            color: $white;
          }
        }
        @-moz-document url-prefix() {
          .cart-add-hope-checkbox {
            margin-top: 0;
          }
        }

        input:checked {
          background-color: $black;
          color: $white;
        }

        .cart-add-hope-content {
          margin-right: 5px;
          margin-left: 5px;

          .cart-add-hope-header {
            width: 159px;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $Brand-Black;
          }

          .cart-add-hope-text {
            width: 163px;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: $Dark-Gray;
          }
        }

        .cart-add-hope-image {
          width: 53px;
          height: 36px;
          object-fit: contain;
        }
      }

      .cart-summary-checkout-button {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .cart-addon-container-desktop {
    background-color: $Brand-Black;
    margin-bottom: 75px;
    margin-top: 100px;

    .indulgent-container {
      display: flex;
      flex-direction: column;
      margin-left: 130px;

      .indulgent-header-container {
        margin-top: 60px;
        margin-bottom: 42px;

        .indulgent-header {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 38px;
          line-height: 43px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $white;
        }
      }

      .indulgent-card-list {
        display: flex;
        margin-bottom: 60px;
        overflow: hidden;

        .slick-list {
          width: 100vw !important;

          .slick-track {
            margin-left: 0 !important;
          }

          .slick-slide {
            margin-right: 20px;

            .indulgent-card {
              width: 280px;
              height: 210px;
              position: relative;
              border-radius: 4px;

              .indulgent-card-image {
                border-radius: 4px;
                background-blend-mode: multiply;
                background: linear-gradient(190.77deg, rgba(32, 33, 36, 0) 21.45%, #202124 98.01%);
                width: 280px;
              }

              .indulgent-card-content {
                position: absolute;
                left: 7.14%;
                right: 10.71%;
                bottom: 20px;

                .indulgent-card-header {
                  font-family: $National2Condensed;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 18px;
                  line-height: 25px;
                  display: flex;
                  align-items: flex-end;
                  letter-spacing: 0.5px;
                  text-transform: uppercase;
                  color: $white;
                }

                .indulgent-card-price {
                  font-family: $National2Regular;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  display: flex;
                  align-items: flex-end;
                  text-align: right;
                  color: $white;
                  padding-bottom: 6px;
                }
              }
            }
          }
        }

        // .slick-next {
        // 	right: 203px !important;

        // 	&::before {
        // 		font-size: 32px !important;
        // 	}
        // }

        // .slick-prev {
        // 	left: 203px !important;

        // 	&::before {
        // 		font-size: 32px !important;
        // 	}
        // }

        .slick-next,
        .slick-prev {
          //right: 120px !important;
          height: 48px;
          width: 48px;
          background-color: $white;
          border-radius: 66px;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
          background-repeat: no-repeat;
          background-position: center;
          z-index: $z-index-max - 1;

          &.slick-disabled {
            opacity: 0.25;
            pointer-events: none;
          }
          &::before {
            display: none;
          }
        }

        .slick-next {
          margin-right: 15% !important;
          transform: rotate(0deg) translate(0, -50%);
          background-image: url('../../../styles/staticAssets/images/corrousel-right.png');
        }

        .slick-prev {
          display: none;
          margin-left: 25px !important;
          transform: rotate(180deg) translate(0, 50%);
          background-image: url('../../../styles/staticAssets/images/corrousel-right.png');
        }
      }

      .indulgent-slideContainer {
        width: 90%;
        margin-bottom: 77px;

        .indulgent-slider {
          width: 100%;
          height: 3px;
          -webkit-appearance: none;
          background: $Dark-Gray;
          outline: none;
          border-radius: 5%;
          -webkit-transition: 0.2s;
          transition: opacity 0.2s;
        }

        .indulgent-slider::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 20%;
          height: 3px;
          border-radius: 5%;
          background: $white;
          cursor: pointer; /* Cursor on hover */
        }

        .indulgent-slider::-moz-range-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 20%;
          height: 2px;
          border-radius: 5%;
          background: $white;
          cursor: pointer; /* Cursor on hover */
          border: 0;
        }
      }
    }
  }

  .cart-faq-container-desktop {
    padding-left: 130px;
    padding-right: 130px;
    padding-bottom: 80px;
    margin-top: 76px;

    .cart-faq-container {
      display: flex;

      .cart-faq-header-container {
        width: 50%;

        .cart-faq-header {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: $Brand-Black;
          padding-bottom: 20px;
          border-bottom: 2px solid $Light-Gray;
        }

        .cart-faq-list {
          display: flex;
          flex-direction: column;

          .cart-accordian-container {
            margin-top: 40px;
            padding-bottom: 40px;
            padding-left: 20px;
            padding-right: 20px;
            border-bottom: 2px solid $Light-Gray;

            .global-accordian-header {
              width: 90%;
            }

            .cart-accordian-content {
              margin-top: 20px;
              font-family: $National2Regular;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: -0.1px;
              color: $Dark-Gray;
            }
          }
        }

        .faq-view-all {
          margin-top: 50px;
        }
      }

      .cart-faq-contact-header-container {
        width: 50%;
        margin-left: 100px;

        .cart-faq-contact-header {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: $Brand-Black;
          padding-bottom: 20px;
          border-bottom: 2px solid $Light-Gray;
        }

        .cart-faq-contact-content {
          display: flex;
          margin-top: 20px;

          .anchorTags {
            text-decoration: none;
          }

          .call-contact-button {
            margin-right: 20px;
            padding: 11px 53px;
            margin-left: -1%;
          }

          .mail-contact-button {
            padding: 11px 53px;
          }

          @media (min-width: 1024px) and (max-width: 1196px) {
            .call-contact-button,
            .mail-contact-button {
              padding: 11px 25px;
            }
          }

          .call-contact-button,
          .mail-contact-button {
            display: flex;
            align-items: center;

            &:hover {
              cursor: pointer;
              background-color: $white;
              color: $Brand-Black;
            }

            &:focus-visible {
              border: 2px solid $white;
              box-shadow: 0 0 0 2px $blue-done;
              background: $white;
              color: $Brand-Black;
            }

            &:active {
              cursor: pointer;
              background: $white;
            }

            .contact-text {
              margin-left: 10px;
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              color: $Brand-Black;
            }
          }
        }
      }
    }
  }
}

.cart-page-container-mobile {
  .cart-header-container-mobile {
    margin-bottom: 22px;
    @media #{$breakpoint-below-desktop-above-tablet} {
      padding-left: 3%;
      padding-right: 3%;
    }

    .cart-header {
      margin-left: 20px;
      margin-top: -3px;
      img {
        width: 39px;
      }
    }

    .cart-header-text {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $Brand-Black;
      margin-top: 12px;
    }
  }

  .cart-offers-mobile {
    .cart-offers-container {
      // padding-left: 30px;
      // padding-right: 30px;
      background-image: url('../../staticAssets/images/offers-bg-mob.png');
      background-size: cover;
      background-position: center;
      padding: 0 7%;
    }
  }

  .cart-content-container-mobile {
    @media #{$breakpoint-below-desktop-above-tablet} {
      padding-left: 5%;
      padding-right: 5%;
    }
    .cart-food-container {
      & .cart-highlight {
        border: 1px solid $Red-Orange;
      }

      & .store-unavailale-cart-page {
        width: 94%;
        margin: 0 auto 11px auto;
        @include storeUunavailaleCartPage;

        @media #{$breakpoint-below-iphone5} {
          height: auto;
        }

        @media #{$breakpoint-iphone-11-pro-portrait} {
          width: 89%;
          margin: 0 20px;
        }

        & .cart-summary-error-message {
          @include cartSummaryUnavailableErrorMessage;
        }
      }

      .cart-discount-errors,
      .cart-notification {
        display: flex;
        flex-direction: column;
        .cart-food-discount-lines,
        .cart-notification-line {
          margin-bottom: 10px;
          .discount-error,
          .notify-alert {
            background: #fff4f2;
            border-radius: 4px;
            width: 94.66%;
            margin: auto;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: #ff2e00;
            padding: 15px 2.7%;
            display: flex;
            img.discount-error-icon,
            img.location-alert-icon {
              margin-right: 1.3%;
              @media #{$breakpoint-below-tablet} {
                margin-right: 2.7%;
              }
              margin-bottom: auto;
              margin-top: 1.2%;
            }
          }
        }
        .cart-food-discount-lines:last-of-type {
          margin-bottom: 20px;
        }
      }

      .global-accordian-container {
        border: none;
        margin-bottom: 5px;

        &:focus {
          outline: none;
        }

        .global-accordian-header-container {
          padding: 0;
        }

        .global-accordion-subheader {
          padding-left: 15px;
          margin: 0;
        }
      }

      .cart-food-item-container {
        margin-top: 5px;
        display: flex;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 20px;
        padding-bottom: 18px;
        background-color: $Mashed-Potato-Gray;
        flex-direction: column;

        .cart-food-image {
          width: 100px;
          height: 75px;
        }
        .cart-image-container {
          width: 100px;
          height: 70px;
          @include backgroundImage();
          img {
            @include cartMainImage();
            @include imageAnimation;
          }
        }
        .cart-food-image,
        .cart-image-container {
          border-radius: 4px;
          // cursor: pointer;
        }

        .outofstock {
          opacity: 0.4;
        }

        .outofstock-text {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $Red-Orange;
          border-radius: 3px;
          margin-bottom: 1%;
          padding-right: 6px;
          justify-content: space-between;
          align-items: center;

          & .location-alert-icon {
            margin-right: 5px;
            margin-top: -1px;
          }
        }

        .image-cart-content {
          width: 100%;
          display: flex;
          border-bottom: 1px solid $Inactive-Gray;
          padding-bottom: 15px;
          margin-bottom: 15px;
        }

        .cart-food-content {
          margin-left: 18px;
          width: 100%;

          .global-accordian-header {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $Brand-Black;
          }

          .cart-food-modifier {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: $Dark-Gray;
            min-width: 180px !important;
            width: 90%;
            margin-top: 0.2rem;

            ul {
              padding-left: 15px;
            }
          }

          .cart-food-edit,
          .cart-food-remove {
            font-family: $National2Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 22px;
            text-decoration-line: underline;
            color: $Brand-Black;
          }

          .cart-food-edit {
            margin-right: 20px;
          }

          .cart-food-edit-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        .cart-food-quantity-change {
          display: flex;
          align-items: center;
          margin-right: 20px;

          @include quantitiyPlusButton();
          @include quantitiyMinusButton();

          .cart-food-quantity {
            font-family: $National2Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            color: $Brand-Black;
            width: 40px;
            text-align: center;
            // margin-left: 16px;
            // margin-right: 16px;
          }
          .sr-only {
            position: absolute;
            left: -10000px;
            top: auto;
            width: 1px;
            height: 1px;
            overflow: hidden;
          }
        }

        .cart-food-price {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $Brand-Black;
          display: flex;
          flex-direction: row;
          column-gap: 8%;
          padding-right: 6px;

          & .modified-price {
            color: $Red-Orange;
          }

          & .old-price {
            text-decoration: line-through;
          }
        }

        .cart-food-quantity-price {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .coupon-item-quantity-price {
          justify-content: flex-end;
        }
      }

      .cart-food-remove-container {
        display: flex;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 30px;
        margin-bottom: 60px;
        align-items: center;

        .cart-food-remove-all,
        .add-more-menu {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: $Brand-Black;
        }

        .cart-food-remove-all {
          text-decoration-line: underline;
        }
      }
    }
  }

  .cart-summary-card-mobile {
    padding: 20px 10px;

    .cart-summary-card {
      padding: 40px 8px;
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      background-color: $white;

      .cart-summary-item-count {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Black;
        margin-bottom: 20px;
        margin-left: 14px;
      }

      .cart-summary-coupons {
        // margin-left: 7px;
        // margin-right: 7px;

        .cart-offers-container {
          background-image: url('../../staticAssets/images/offers-bg-mob.png');
          background-size: cover;
          background-position: center;
          padding: 0 7%;
        }
      }

      .cart-summary-content {
        margin-top: 20px;
        margin-right: 14px;
        margin-left: 14px;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        margin-bottom: 22px;
        border-bottom: 1px solid $Light-Gray;

        .cart-summary-discount-lines {
          .discount-error {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: #ff2e00;
            display: $d-flex;
            margin: 6px 0;
            img.discount-error-icon {
              width: 15px;
              height: 15px;
              margin: 1.5% 3.6% auto 0;
            }
          }
        }

        .cart-summary-content-item {
          display: flex;
          justify-content: space-between;

          .cart-summary-content-item-name {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: $Dark-Gray;
            text-transform: capitalize;

            .cart-discount-code {
              white-space: nowrap;
              font-weight: 500;
              margin-right: 5%;
            }

            .cart-discount-clear-icon {
              vertical-align: middle;
              cursor: pointer;
            }
          }

          .cart-summary-content-item-name.d-flex {
            display: $d-flex;
          }

          .cart-summary-content-item-value {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: right;
            color: $Brand-Black;
          }
        }
      }

      .cart-carry-bag-container {
        display: flex;
        align-items: center;
        margin-left: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        margin-right: 12px;
        border-bottom: 1px solid $Light-Gray;

        .cart-carry-bag-checkbox {
          margin-right: 12px;
        }

        .cart-carry-bag-header {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $Brand-Black;
          margin-bottom: 0;
        }
      }

      .cart-add-hope-container {
        border: 1px solid $Light-Gray;
        border-radius: 3px;
        padding: 15px 16px 10px 16px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .cart-add-hope-checkbox {
          align-self: normal;
          margin-top: 5px;
        }
        @-moz-document url-prefix() {
          .cart-add-hope-checkbox {
            margin-top: 0;
          }
        }
        .cart-add-hope-content {
          margin-left: 15px;
          margin-right: 15px;

          .cart-add-hope-header {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $Brand-Black;
          }

          .cart-add-hope-text {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: $Dark-Gray;
          }
        }

        .cart-add-hope-image {
          width: 57px;
          height: 69px;
          object-fit: contain;
        }
      }

      .cart-summary-checkout-button-mobile {
        background: linear-gradient(360deg, #fff 55.13%, rgba(255, 255, 255, 0.7) 77.56%, rgba(255, 255, 255, 0) 100%);

        .cart-summary-checkout-button {
          width: 90%;
          display: flex;
          text-align: center;
          justify-content: space-between;
          margin: auto;
        }
      }

      .fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .cart-addon-container-mobile {
    background-color: $Brand-Black;

    .indulgent-addons-modal {
      .modal-header {
        h2 {
          font-size: 14px;
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: #494949;
        }
      }
    }

    .indulgent-container {
      padding-top: 60px;
      padding-left: 20px;
      padding-bottom: 103px;

      .indulgent-header-container {
        display: flex;
        align-items: baseline;
        margin-bottom: 32px;
        justify-content: space-between;

        .indulgent-header {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 22px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $white;
        }

        .indulgent-carousal-count {
          color: $white;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 22px;
          text-align: right;
          opacity: 0.7;
          margin-right: 20px;
        }
      }

      .indulgent-card-list {
        .slick-slide {
          margin-right: 15px;
          opacity: 0.25;

          &.slick-active {
            opacity: 1;
          }

          .indulgent-card {
            height: 225px;
            position: relative;
            border-radius: 4px;

            .indulgent-card-image {
              @media #{$breakpoint-below-iphone5} {
                width: 280px !important;
              }
              height: 100%;
              border-radius: 4px;
            }

            .indulgent-card-content {
              position: absolute;
              left: 6.67%;
              right: 16.67%;
              top: 50.22%;
              bottom: 40.89%;

              .indulgent-card-header {
                font-family: $National2Condensed;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                display: flex;
                align-items: flex-end;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: $white;
              }

              .indulgent-card-price {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: flex-end;
                text-align: right;
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  .cart-faq-container-mobile {
    padding-top: 50px;
    padding-bottom: 50px;

    .cart-faq-container {
      .cart-faq-header-container {
        .cart-faq-header {
          margin-left: 20px;
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 22px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $Brand-Black;
        }

        .cart-faq-list {
          margin-top: 20px;
          margin-bottom: 30px;

          .cart-accordian-container {
            padding-top: 18px;
            padding-bottom: 18px;
            border-top: 1px solid $Light-Gray;
            border-bottom: 1px solid $Light-Gray;

            .global-accordian-container {
              padding-left: 15px;
              padding-right: 15px;
              border: none;

              .global-accordian-header {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $Brand-Black;
              }
            }
          }
        }

        .faq-view-all {
          display: flex;
          margin: auto;
        }
      }

      .cart-faq-contact-header-container {
        margin-top: 60px;

        .cart-faq-contact-header {
          margin-left: 20px;
          padding-bottom: 20px;
          margin-right: 20px;
          border-bottom: 1px solid $Light-Gray;
          margin-bottom: 30px;
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 22px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $Brand-Black;
        }

        .cart-faq-contact-content {
          display: flex;
          justify-content: space-evenly;

          .anchorTags {
            text-decoration: none;
          }

          .call-contact-button {
            width: 160px;
            display: flex;
            align-items: center;
            justify-content: center;

            .contact-text {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              margin-left: 5px;
            }

            &:hover {
              img {
                content: url('../../staticAssets/images/Call-white.svg');
              }
            }
          }

          .mail-contact-button {
            width: 160px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .contact-text {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              margin-left: 5px;
            }

            &:hover {
              img {
                content: url('../../staticAssets/images/Mail_Icon_White.svg');
              }
            }
          }
        }
      }
    }
  }
}

.unavailable-image {
  opacity: 0.4;
}

.main-wicode-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

  & .cart-coupon-wicode-offers-container {
    background: none;
  }
  & .coupon-code-validation-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    margin-top: 20px;
    padding: 0 18px;
    @media #{$breakpoint-below-tablet} {
      padding: 0 14px;
    }

    hr {
      width: 100%;
      background: $gray-lighter;
    }
  }

  & .coupon-discount-col-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    & .coupon-text-box {
      color: $Brand-Black;
      font-family: $National2Regular;
      width: 100%;
      font-style: normal;
      font-size: 14px;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.1px;
      border: none;
      border-bottom: 1px solid $Brand-Black;
      outline: none;
      &:disabled {
        border-bottom: none;
        background-color: $white;
        color: $Brand-Black;
      }
    }

    & .coupon-text-box-err {
      border-bottom: 1px solid $Brand-Red;
    }
    & .error-msg {
      color: $gray-lightbg;
    }

    .flex-input-section {
      display: flex;
      position: relative;
      width: 100%;
    }

    .discount-button-img {
      background: white;
      width: 16px;
      position: absolute;
      right: 4.1%;
      border: none;
      bottom: 100%;
    }
    .discount-button-img-disable {
      opacity: 0;
    }
    .discount-button-img::before {
      content: url('../../../styles/staticAssets/images/Darkclosebutton.svg');
      background-size: 12px;
      border: none;
      background-repeat: no-repeat;
      background-color: white;
      position: absolute;
    }

    & .error-container-coupon {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.1px;
      font-size: 14px;
      margin-top: 8px;
    }

    & .coupon-input-label {
      color: $Brand-Black;
      font-family: $National2Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.1px;
      opacity: 0;
      transition: 0.2s ease all;
    }
    .coupon-input-label-focus {
      opacity: 1;
    }

    @media #{$breakpoint-below-tablet} {
      width: 100% !important;
    }

    .input-dark-cross {
      top: 40%;
      cursor: pointer;
      position: relative;
      right: 20px;
    }
  }
  .coupon-text-box::placeholder {
    color: $Brand-Black;
    font-weight: 400;
  }
  .coupon-code-validation-container:focus-within .coupon-text-box::placeholder {
    opacity: 0;
  }

  .coupon-code-validation-container:focus-within .coupon-input-label {
    opacity: 1;
  }
  .coupon-discount-col-3 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin-bottom: 22px;
    margin-top: 45px;

    & .coupon-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 22px;
      background: $Brand-Black;
      font-family: $National2Medium;
      font-style: normal;
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      color: $white;
      min-width: unset;
      height: 44px;
      width: 123px;
    }
    & .coupon-button:disabled {
      color: $Dark-Gray;
      background: $Light-Gray;
      border: none !important;
    }

    @media #{$breakpoint-below-tablet} {
      width: 90%;
    }
  }
}

.cart-offers-container {
  width: 100%;
  height: 50px;
  background-image: url('../../staticAssets/images/offers-bg-desk.png');
  background-size: contain;
  padding: 0 18px 0 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{$breakpoint-below-tablet} {
    padding: 0 14px;
  }
  .cart-offers-link {
    .cart-offers-text {
      margin-left: 6px;
      font-family: $National2Medium;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $Brand-Red;
    }
  }
  & .add-gift-card-voucher {
    color: $Brand-Black;
    text-align: right;
    font-family: $National2Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-decoration-line: underline;
  }
  & .add-gift-card-voucher-disable {
    pointer-events: none;
    color: $gray-lightbg;
    outline: none;
    text-decoration-line: none;
    opacity: 0.5;
  }
  .cart-wicode-offers-link {
    display: flex;
    & .cart-wicode-offers-text {
      margin-left: 6px;
      font-family: $National2Medium;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $Brand-Black;
    }
  }
  .cart-offers-view-all {
    width: 64px;
    height: 27px;
    padding: 0;
    font-family: $National2Medium;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: $Brand-Black;
  }
}

.cart-notification {
  display: flex;
  flex-direction: column;
  //margin: 0 7.5%;

  @media #{$breakpoint-below-tablet} {
    margin: 0 2% 3%;
  }

  & .notify-alert {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: $Red-Orange;
    background: #fff4f2;
    border-radius: 4px;
    display: flex;
    padding: 15px;
    width: 100%;
    margin-bottom: 1%;

    @media #{$breakpoint-below-tablet} {
      width: 100%;
      padding: 15px;
    }

    & .location-alert-icon {
      width: 15px;
      height: 15px;
      margin: 4px 10px 4px 5px;

      @media #{$breakpoint-below-tablet} {
        margin: 4px 10px 4px 0;
      }
    }
  }
}

.cart-empty {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  padding-top: 20px;
  margin-top: 11px;
  font-family: $National2Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 48px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: $Brand-Black;
}

.cart-empty-root {
  display: flex;
  align-items: center;
  background: #f8f7f5;
  margin-bottom: 80px;

  @media #{$breakpoint-below-tablet} {
    flex-direction: column;
    margin-bottom: 60px;
  }

  & .cart-empty-placeholder-view {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: center;
    flex: 1;
    padding-left: 100px;
    padding-right: 100px;

    @media #{$breakpoint-below-tablet} {
      padding-left: 0;
      padding-right: 0;
    }
    @media #{$breakpoint-iphone13-pro-max} {
      padding-left: 17px;
    }

    & .cart-empty-placeholder-text {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 64px;
      line-height: 76px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #202124;
      margin-bottom: 30px;

      @media #{$breakpoint-below-tablet} {
        font-size: 36px;
        line-height: 36px;
        margin-top: 44px;
        margin-left: 35px;
        margin-right: 25px;
      }
      @media #{$breakpoint-iphone13-pro-max} {
        width: 80%;
      }
    }

    & .cart-empty-placeholder-button {
      @media #{$breakpoint-below-tablet} {
        margin-left: 44.5px;
      }
    }
  }
}

.cart-empty-img {
  padding-top: 44px;

  @media #{$breakpoint-below-tablet} {
    display: flex;
    align-self: flex-end;
    padding-top: 18px;
    width: 269px;
    height: 258px;
    margin-right: 14px;
  }
  @media #{$breakpoint-iphone13-pro-max} {
    align-self: center;
  }
}

.cart-recomendations-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding-top: 5rem;
  border-bottom: 1px solid #e3e3e3;
  margin-left: 7.5%;

  & .cart-recomendations-title {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 43px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #202124;
  }

  & .cart-recomendations-list-container {
    margin-top: 28px;

    .slick-track {
      min-width: 100%;
    }

    .slick-next,
    .slick-prev {
      height: 48px;
      width: 48px;
      background-color: $white;
      border-radius: 66px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
      background-repeat: no-repeat;
      background-position: center;
      z-index: $z-index-max - 1;

      &.slick-disabled {
        opacity: 0.25;
      }
    }

    .slick-next {
      margin-right: 5%;
      transform: rotate(0deg) translate(0, -50%);
      background-image: url('../../../styles/staticAssets/images/corrousel-right.png');
    }

    .slick-prev {
      transform: rotate(180deg) translate(0, 50%);
      background-image: url('../../../styles/staticAssets/images/corrousel-right.png');
    }

    .recomendation-item-container {
      display: flex;
      flex-direction: column;
      width: 280px;
      height: 452px;
      background: #fff;
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin-right: 20px;
      margin-bottom: 40px;

      .recomendation-item-img {
        border-radius: 8px 8px 0 0;
        width: 280px;
        height: 210px;
      }

      .recomendation-item-header {
        display: flex;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 10px;

        .recomendation-item-name {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #202124;
          width: 166px;
          flex: 1;
          margin-right: 10px;
        }

        .recomendation-item-price {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: right;
          color: #202124;
        }
      }

      .recomendation-item-serving-conatiner {
        display: flex;
        margin: 5px 20px;
        align-items: center;

        @media #{$breakpoint-below-tablet} {
          margin-bottom: 23px;
          margin-top: 12px;
        }

        .recomendation-item-serving-type {
          width: 9px;
          height: 9px;
        }

        .recomendation-item-serving {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          color: #494949;
          margin-left: 7px;

          @media #{$breakpoint-below-tablet} {
            padding-top: 2px;
          }
        }
      }

      .recomendation-item-desc {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: #494949;
        margin: 0 20px;

        @media #{$breakpoint-above-tablet} {
          flex-grow: 1;
        }

        @media #{$breakpoint-below-tablet} {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          min-height: 75px;
        }
      }

      .recomendation-item-actions {
        display: flex;
        margin: 0 20px;
        margin-bottom: 20px;
        justify-content: center;

        .recomendation-item-add-to-cart {
          width: 176px;
        }

        .recomendation-item-save {
          margin-left: 22px;
        }
      }
    }
  }
}

.cart-recomendations-slideContainer {
  width: 90%;
  margin-bottom: 80px;

  .cart-recomendations-slider {
    width: 100%;
    height: 3px;
    -webkit-appearance: none;
    background: #e3e3e3;
    outline: none;
    border-radius: 5%;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  .cart-recomendations-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20%;
    height: 3px;
    border-radius: 5%;
    background: #202124;
    cursor: pointer; /* Cursor on hover */
  }

  .cart-recomendations-slider::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20%;
    height: 2px;
    border-radius: 5%;
    background: #202124;
    cursor: pointer; /* Cursor on hover */
    border: 0;
  }
}

.alert-model-view-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.alert-action-container {
  margin-top: 40px;
  width: 90%;
  justify-content: space-evenly;
  display: flex;
}

.button-contained {
  margin-left: 5px;
  margin-right: 5px;

  @media #{$breakpoint-below-tablet} {
    margin: auto;
    // margin-left: 35px;
    // margin-right: 5px;
  }
}

.alet-modal {
  z-index: $z-index-max + 1;
  width: 100%;
  &.schedule-time-lapsed {
    .button-contained {
      min-width: inherit;
    }
  }
  & .modal-content {
    width: 40%;
    height: 252px;
    left: 30%;
    top: 30%;
    background: $white;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    @media #{$breakpoint-below-tablet} {
      width: 100%;
      bottom: 0;
      left: 0;
      top: unset;
      border-radius: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    & .modal-header {
      padding: 4% 1%;
      border-bottom: none;

      .custom-modal-close {
        @media #{$breakpoint-below-tablet} {
          right: 1%;
        }
      }

      @media #{$breakpoint-below-tablet} {
        padding-top: 30px !important;
        padding-bottom: 20px !important;
        border-radius: 0;
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 22px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    & .modal-body {
      box-shadow: none;
      background: $white;

      @media #{$breakpoint-below-tablet} {
        padding: 30% 10%;
      }

      @media #{$medium-only} {
        padding: 5% 10%;
      }

      @media #{$mobile-only} {
        padding: 10% 10%;
      }

      .alert-title {
        @media #{$breakpoint-below-tablet} {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 22px;
          text-align: center;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $Brand-Black;
        }
      }

      .alert-cancel {
        background-color: white;
        // margin-right: 15px;

        &:hover {
          background-color: $Brand-Black;
          color: $white;
        }
      }
      /*
			.alert-confirm,
			.alert-cancel {
				width: 156px;
				height: 44px;
				white-space: nowrap;
				padding: inherit;
			}
			*/
    }
  }
}

.cart-food-item-coupon-container {
  position: relative;
  display: block !important;
  width: 98%;
  margin: auto;
  & :before {
    width: 14px;
    height: 10px;
    background-color: #fff;
    border-top-left-radius: 110px;
    border-top-right-radius: 110px;
    //border: 1px solid #F79C34;
    border-bottom: 0;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    left: -3px;
  }
  & :after {
    width: 14px;
    height: 10px;
    background-color: #fff;
    border-top-left-radius: 110px;
    border-top-right-radius: 110px;
    //border: 1px solid #F79C34;
    border-bottom: 0;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    right: -3px;
  }
  & .cart-food-card-item-edit-remove {
    font-family: '$National2Medium';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    text-decoration-line: underline;
    color: $Brand-Black;
    margin-top: 12px;
  }
  & .cart-offers-container {
    padding: 10px;
    height: 60px;
    text-align: center;
    background-image: none;

    @media #{$breakpoint-below-tablet} {
      display: block;
      padding: 0px;
      justify-content: center;
    }

    & .cart-offers-link {
      width: 95%;
      display: flex;
      justify-content: center;
      vertical-align: middle;

      & .cart-offers-text {
        font-weight: 700;
        font-size: 20px;
        color: $Brand-Black;
        text-transform: uppercase;
        line-height: 30px;
        font-family: $National2Condensed;
        font-style: normal;
      }
      & .cart-offers-icon {
        margin-right: 4px;
        margin-top: 0px !important;
      }
    }
    & .cart-food-card-item-edit-remove {
      margin-top: -3px;
      font-weight: 500 !important;
      font-family: $National2Regular;
      cursor: pointer;
      margin-right: 5px;
    }
  }
  & .promos-summary-cart-notification {
    width: 50% !important;
    margin: auto;
    @media #{$breakpoint-below-tablet} {
      width: 95% !important;
      margin-top: 10px;
    }
    & .promos-error-massage {
      font-size: 16px;
      font-family: $National2Regular;
    }
    //& .promos-error-massage::first-letter{
    //	text-transform: capitalize!important;
    //}
    & .promos-error-list {
      margin-bottom: 5px;
      margin-left: -15px;
    }
    & .promos-error-list:first-letter {
      text-transform: capitalize;
    }
    & .cart-promos-signinup-text {
      font-weight: 500 !important;
      cursor: pointer;
      font-family: $National2Medium;
    }
  }
}
.cart-food-item-coupon-container.invalid {
  border: 1px solid #f79c34;
  & :before {
    border: 1px solid #f79c34;
    border-bottom: 0;
  }
  & :after {
    border: 1px solid #f79c34;
    border-bottom: 0;
  }
}

.accordian-container .cart-food-quantity-change-sopac,
.pdp-custm-container-sopac .accordian-container {
  & .red-quantity-decrease {
    background-color: $Brand-Red;
    border: 1px solid $Brand-Red;
    background-image: url('../../staticAssets/images/cart-minus-active-white.svg');
  }
  & .red-quantity-increase {
    background-color: $Brand-Red;
    border: 1px solid $Brand-Red;
    background-image: url('../../staticAssets/images/cart-plus-white.svg');

    &:disabled {
      background-color: transparent !important;
      color: #dbdbdb !important;
      border-color: #202124 !important;
      cursor: not-allowed;
      opacity: 0.2;
      background-image: url('../../staticAssets/images/cart-plus.svg') !important;
    }
  }
  & .red-quantity-increase:disabled,
  & .red-quantity-decrease:disabled {
    opacity: 0.15 !important;
  }
  & .gray-quantity-increase {
    background-image: url('../../staticAssets/images/cart-plus.svg');
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    border-color: $Brand-Black;
  }
  & .quantity-decrease-disable {
    background-image: url('../../staticAssets/images/cart-minus-disabled.svg');
    outline: none;
    background-color: transparent;
    color: #dbdbdb;
    border-color: #dbdbdb;
    cursor: not-allowed;
  }
}
.sopac-modifier-customizeblock.modgrp-greyout,
.pdp-custm-container-sopac .accordian-container .modgrp-greyout {
  & .red-quantity-increase {
    background-color: transparent !important;
    border: 1px solid #dbdbdb !important;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.cart-checkout-alert {
  .modal-content {
    @media #{$breakpoint-above-tablet} {
      width: 38%;
    }
    .modal-header {
      padding: 6% 1%;
    }
    .alert-title {
      padding: 0% 19% !important;
      @media #{$breakpoint-between-tablet-desktop} {
        padding: 0% 15% !important;
      }
      @media #{$mobile-only} {
        padding: 0% 10% !important;
      }
    }
    .alert-content {
      width: 50% !important;
      @media #{$breakpoint-between-tablet-desktop} {
        width: 55% !important;
      }
      @media #{$mobile-only} {
        width: 60% !important;
      }
    }
  }
  &.schedule-time-lapsed {
    & .modal-header {
      padding: 4% 1%;
    }
    & .modal-body {
      padding: 0px;
      @media #{$breakpoint-below-tablet} {
        padding: 10px 20px;
      }
      img {
        width: unset;
        height: 105px;
      }
    }
    .modal-content {
      @media #{$breakpoint-above-tablet} {
        height: 390px !important;
        .alert-model-view-root {
          height: 360px;
        }
      }
      .alert-title {
        padding: 0% 15% !important;
        font-size: 40px;
        line-height: 40px;
        @media #{$breakpoint-between-tablet-desktop} {
          padding: 0% 10% !important;
          font-size: 40px;
          line-height: 40px;
        }
        @media #{$mobile-only} {
          padding: 0% 0% !important;
          font-size: 32px;
          line-height: 100%;
          width: 289px;
        }
      }
      .alert-content {
        width: 100% !important;
      }
      .button-contained {
        min-width: inherit;
      }
      .alert-action-container {
        button:first-child {
          background-color: $black;
          color: $white;
          margin-right: 15px;
        }
        button:nth-child(2) {
          background-color: $white;
          color: $black;
          border: 1.5px solid $black;
        }
        @media #{$mobile-only} {
          flex-wrap: wrap;
          button:first-child {
            width: 100%;
            margin-bottom: 15px;
            margin-right: 0px;
          }
          button:nth-child(2) {
            width: 100%;
          }
        }
      }
    }
  }
  &.order-capacity-error .modal-content {
    @media #{$breakpoint-above-tablet} {
      height: 410px !important;
    }
  }
}
.cart-toast-alert {
  width: auto;
  display: inline-flex;
}
